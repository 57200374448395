import GoingBack from "../../components/GoingBack/GoingBack";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import GetProductById from "../../services/GetProductById";
import IsAdmin from "../../components/Admin/IsAdmin";
import Cookies from "js-cookie";
import uploadImage from "../../services/UploadImage";
import formValid from "../../services/FormProductValid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import UpdateOrCreateAnewStripeProductId from "../../services/UpdateOrCreateAnewStripeProductId";
import './AdminProductInDetails.scss'

function AdminProductInDetails() {
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(null);
    const [product, setProduct] = useState(null);
    const [productTitle, setProductTitle] = useState("");
    const [productDescription, setProductDescription] = useState("");
    const [productPrice, setProductPrice] = useState("");
    const [productImage, setProductImage] = useState("");
    const [productCategory, setProductCategory] = useState("");
    const [productSousCategory, setProductSousCategory] = useState("");
    const [productPierres, setProductPierres] = useState("");
    const [productColors, setProductColors] = useState("");
    const [productOnSale, setProductOnSale] = useState(false);
    const {id} = useParams();
    const [image, setImage] = useState([]);
    const [fileName, setFileName] = useState('Choisissez un fichier');
    const [lienImageProduit, setLienImageProduit] = useState([]);
    const [conditionsRemplies, setConditionsRemplies] = useState(false);
    const [idPrixStripe, setIdPrixStripe] = useState(null);

    useEffect(() => {
        if (isAdmin === null) {
            return;
        }
        !isAdmin && navigate("/");
    }, [isAdmin]);

    useEffect(() => {
        GetProductById(id, setProduct);
    }, []);

    useEffect(() => {
        if (!product) {
            return;
        }
        console.log(product[0].covers)
        setProductTitle(product[0].name);
        setProductDescription(product[0].description);
        setProductPrice((product[0].price).toFixed(2));
        setProductCategory(product[0].category);
        let transformedColors = product[0].color.map(color => color.replace(/ /g, "_"));
        setProductColors(transformedColors.join(","));
        let transformedPierres = product[0].pierres.map(pierre => pierre.replace(/ /g, "_"));
        setProductPierres(transformedPierres.join(","));
        setProductSousCategory(product[0].secondary_category);
        setProductImage(product[0].covers);
        setProductOnSale(product[0].onSale);
    }, [product]);

    const updateProduct = async () => {
        if (await formValid(productTitle, productCategory, productDescription, productSousCategory, productPrice, image) === false) {
            Swal.fire({
                text: "Veuillez remplir tous les champs.",
                icon: "error",
                showConfirmButton: true
            })
            return;
        }
        if (productPrice !== product[0].price) {
            console.log("prix formulaire ------------------------- " + productPrice)
            console.log("prix bdd ------------------------- " + product[0].price)
            const simpleProduct = {
                name: productTitle,
                description: productDescription,
                images: [productImage],
                price: productPrice
            }
            const idPrixStripe = product[0].idPrixStripe;
            console.log("idPrixStripe ------------------------- " + idPrixStripe)
            await UpdateOrCreateAnewStripeProductId(simpleProduct, idPrixStripe, setIdPrixStripe);
        }
        if (image.length > 0) {
            await uploadImage(formValid(productTitle, productCategory, productDescription, productSousCategory, productPrice, image), setLienImageProduit, image)
        }
        setConditionsRemplies(true);
    }

    const handleClickDeleteImage = (index) => {
        const newImages = product[0].covers.filter((cover, i) => i !== index);
        setProduct(prevState => {
            return [{...prevState[0], covers: newImages}];
        });
    }

    const handleImageUpload = event => {
        const files = Array.from(event.target.files);
        setImage(files);
        const fileNames = files.map(file => file.name).join('\n');
        setFileName(fileNames);
    };

    useEffect(() => {
        const updateProduct = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}products/update/${id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("auth_token")}`,
                    },
                    body: JSON.stringify({
                        name: productTitle,
                        description: productDescription,
                        price: productPrice,
                        category: productCategory,
                        secondary_category: productSousCategory,
                        covers: product[0].covers.concat(lienImageProduit),
                        onSale: productOnSale,
                        pierres: productPierres === "" ? null : productPierres,
                        color: productColors === "" ? null : productColors,
                        idPrixStripe: idPrixStripe ? idPrixStripe.idPrixStripe : null
                    }),
                });
                const data = await response.json();
                if (response.ok) {
                    Swal.fire({
                        text: "Produit modifié avec succès.",
                        toast: true,
                        position: "top-end",
                        timerProgressBar: true,
                        showConfirmButton: false,
                        timer: 3000,
                        icon: "success"
                    }).then(() => {
                        navigate(-1);
                    });
                }
                console.log(data);
            } catch (error) {
                console.log(error);
            }
        }
        updateProduct();
    }, [lienImageProduit, conditionsRemplies, idPrixStripe]);

    return (
        <div>
            <IsAdmin setAdmin={setIsAdmin}/>
            <GoingBack/>
            {!isAdmin ? null : (
                <div className={'main-div-details-product-admin'}>
                    <label>Titre</label>
                    <input value={productTitle} onChange={(e) => setProductTitle(e.target.value)}/>
                    <label>Prix</label>
                    <input value={productPrice} onChange={(e) => setProductPrice(e.target.value)}/>
                    <label>Catégorie</label>
                    <input value={productCategory} onChange={(e) => setProductCategory(e.target.value)}/>
                    {productCategory === "Bijoux" && (
                        <>
                            <label>Pierres - Exemple (Diamant,Rubis,Cristal_De_Roche)</label>
                            <input value={productPierres} onChange={(e) => setProductPierres(e.target.value)}/>
                        </>
                    )}
                    {productCategory === "Bijoux" && (
                        <>
                            <label>Couleurs - Exemple (Rouge,Gris_tacheté)</label>
                            <input value={productColors} onChange={(e) => setProductColors(e.target.value)}/>
                        </>
                    )
                    }
                    <label>Sous-catégorie</label>
                    <input value={productSousCategory} onChange={(e) => setProductSousCategory(e.target.value)}/>
                    <label>Description</label>
                    <textarea style={{height: "10em"}} value={productDescription}
                              onChange={(e) => setProductDescription(e.target.value)}/>
                    <label style={{width: "100%", textAlign: "center", margin: "0 0 2em 0"}}>
                        <input
                            style={{width: "1%"}}
                            type="checkbox"
                            checked={productOnSale}
                            onChange={(e) => setProductOnSale(e.target.checked)}
                        />
                        Produit disponible à l'achat
                    </label>
                    <p style={{margin: "0 0 0.5em 0"}}>Ajouter d'autres photos :</p>
                    <input type="file" id="file" style={{display: 'none'}} multiple onChange={handleImageUpload}/>
                    <label htmlFor="file"
                           style={{
                               padding: '10px',
                               margin: "0 0 1em 0",
                               backgroundColor: '#22242A',
                               color: 'white',
                               cursor: 'pointer',
                               maxWidth: "20%"
                           }}>
                        {fileName}
                    </label>
                    <label>Images Actuels</label>
                    <div className={'div-images-produit-details-admin'}>
                        {product && product[0].covers.map((cover, index) => (
                            <div className={'secondary-div-images-details-product-admin'}>
                                <img key={index} height={100} width={100} src={cover}
                                     alt={"détails produit collections"}/>
                                <FontAwesomeIcon icon={faTrash}
                                                 style={{cursor: "pointer", fontSize: "1.5em"}}
                                                 onClick={() => handleClickDeleteImage(index)}/>
                            </div>
                        ))}
                    </div>
                    <button style={{margin: "2em 0 5em 0"}} onClick={() => updateProduct()}>Modifier le produit</button>
                </div>
            )
            }

        </div>
    )
        ;
}

export default AdminProductInDetails;