import Swal from "sweetalert2";

const sendVerificationEmail = async (email) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}auth/send-verification-email`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({email}),
            }
        );
        if (response.ok) {
            Swal.fire({
                text: "Compte créé avec succès !",
                icon: "success",
                timer: 4000,
                showConfirmButton: false,
                toast: true,
                position: "top-end",
                timerProgressBar: true,
            });
            console.log("Email de vérification envoyé avec succès !");
        } else {
            console.error("Erreur lors de l'envoi de l'email de vérification");
        }
    } catch (error) {
        console.error("Erreur de connexion au serveur:", error);
    }
};

export default sendVerificationEmail;