function PaimentSecurise() {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            margin: "2em"
        }}>
            <h1>Paiment sécurisé</h1>
            <p>Nous disposons d’un système de cryptage SSL pour protéger vos données personnelles ainsi que les moyens
                de paiement utilisés. </p>
            <p>Nous utilisons le système de paiement sécurisé de Stripe.
            </p>
            <p>Important à savoir sur le paiement par carte bancaire : Toutes les données de votre commande et de votre
                paiement sont envoyées de manière cryptée par le protocole SSL.
            </p>
            <p>Il est précisé que seul l’établissement financier, prestataire du service de sécurisation de transaction,
                reçoit les données bancaires personnelles, nous n'avons aucun accès à l'information auprès des numéros
                de ca rte bancaire de nos clients.
            </p>
            <p>
                Autrement dit nous recevons seulement la confirmation du paiement à travers nos institutions bancaires.
            </p>
            <p>Contact : Si vous avez n'importe quelle question surtout n'hésitez pas, notre équipe vous répondra le
                plus rapidement possible.
            </p>
        </div>
    );
}

export default PaimentSecurise;