function PolitiqueConfidentialite() {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            width: "100%",
            margin: "3em"
        }}>
            <h1 style={{marginBottom: "1em", fontSize: "50px"}}>Politique de confidentialité</h1>

            <div>
                <h2>ARTICLE 1 – RENSEIGNEMENTS PERSONNELS RECUEILLIS</h2>
                <p>
                    Lorsque vous effectuez un achat sur notre boutique, dans le cadre de notre processus d’achat et de
                    vente, nous recueillons les renseignements personnels que vous nous fournissez, tels que votre nom,
                    votre adresse et votre adresse e-mail.

                    Lorsque vous naviguez sur notre boutique, nous recevons également automatiquement l’adresse de
                    protocole
                    Internet (adresse IP) de votre ordinateur, qui nous permet d’obtenir plus de détails au sujet du
                    navigateur et du système d’exploitation que vous utilisez.
                </p>
            </div>
            <div>
                <h2>ARTICLE 2 - CONSENTEMENT</h2>
                <p>
                    Comment obtenez-vous mon consentement ?

                    Lorsque vous nous fournissez vos renseignements personnels pour conclure une transaction, vérifier
                    votre
                    carte de crédit, passer une commande, planifier une livraison ou retourner un achat, nous présumons
                    que
                    vous consentez à ce que nous recueillions vos renseignements et à ce que nous les utilisions à cette
                    fin
                    uniquement.

                    Si nous vous demandons de nous fournir vos renseignements personnels pour une autre raison, à des
                    fins
                    de marketing par exemple, nous vous demanderons directement votre consentement explicite, ou nous
                    vous
                    donnerons la possibilité de refuser.

                    Comment puis-je retirer mon consentement ?

                    Si après nous avoir donné votre consentement, vous changez d’avis et ne consentez plus à ce que nous
                    puissions vous contacter, recueillir vos renseignements ou les divulguer, vous pouvez nous en aviser
                    en
                    nous contactant à anneso.naturelle@gmail.com.
                </p>
            </div>

            <div>
                <h2>ARTICLE 3 – DIVULGATION</h2>
                <p>
                    Nous pouvons divulguer vos renseignements personnels si la loi nous oblige à le faire ou si vous
                    violez
                    nos Conditions Générales de Vente et d’Utilisation.
                </p>
            </div>
            <div>
                <h2>ARTICLE 4 – SERVICES FOURNIS PAR DES TIERS</h2>
                <p>
                    De manière générale, les fournisseurs tiers que nous utilisons vont uniquement recueillir, utiliser
                    et
                    divulguer vos renseignements dans la mesure du nécessaire pour pouvoir réaliser les services qu’ils
                    nous
                    fournissent.

                    Cependant, certains tiers fournisseurs de services, comme les passerelles de paiement et autres
                    processeurs de transactions de paiement, possèdent leurs propres politiques de confidentialité quant
                    aux
                    renseignements que nous sommes tenus de leur fournir pour vos transactions d’achat.

                    En ce qui concerne ces fournisseurs, nous vous recommandons de lire attentivement leurs politiques
                    de
                    confidentialité pour que vous puissiez comprendre la manière dont ils traiteront vos renseignements
                    personnels.

                    Il ne faut pas oublier que certains fournisseurs peuvent être situés ou avoir des installations
                    situées
                    dans une juridiction différente de la vôtre ou de la nôtre. Donc si vous décidez de poursuivre une
                    transaction qui requiert les services d’un fournisseur tiers, vos renseignements pourraient alors
                    être
                    régis par les lois de la juridiction dans laquelle ce fournisseur se situe ou celles de la
                    juridiction
                    dans laquelle ses installations sont situées.

                    Une fois que vous quittez le site de notre boutique ou que vous êtes redirigé vers le site web ou
                    l’application d’un tiers, vous n’êtes plus régi par la présente Politique de Confidentialité ni par
                    les
                    Conditions Générales de Vente et d’Utilisation de notre site web.

                    Liens

                    Vous pourriez être amené à quitter notre site web en cliquant sur certains liens présents sur notre
                    site. Nous n’assumons aucune responsabilité quant aux pratiques de confidentialité exercées par ces
                    autres sites et vous recommandons de lire attentivement leurs politiques de confidentialité.
                </p>
            </div>

            <div>
                <h2>ARTICLE 5 – ÂGE DE CONSENTEMENT</h2>
                <p>
                    En utilisant ce site, vous déclarez que vous avez au moins l’âge de la majorité dans votre État ou
                    province de résidence, et que vous nous avez donné votre consentement pour permettre à toute
                    personne
                    d’âge mineur à votre charge d’utiliser ce site web.
                </p>

            </div>

            <div>
                <h2>ARTICLE 6 – MODIFICATIONS APPORTÉES À LA PRÉSENTE POLITIQUE DE CONFIDENTIALITÉ</h2>
                <p>
                    Nous nous réservons le droit de modifier la présente politique de confidentialité à tout moment,
                    donc
                    veuillez s’il vous plait la consulter fréquemment. Les changements et les clarifications prendront
                    effet
                    immédiatement après leur publication sur le site web. Si nous apportons des changements au contenu
                    de
                    cette politique, nous vous aviserons ici qu’elle a été mise à jour, pour que vous sachiez quels
                    renseignements nous recueillons, la manière dont nous les utilisons, et dans quelles circonstances
                    nous
                    les divulguons, s’il y a lieu de le faire.

                    Si notre boutique fait l’objet d’une acquisition par ou d’une fusion avec une autre entreprise, vos
                    renseignements pourraient être transférés aux nouveaux propriétaires pour que nous puissions
                    continuer à
                    vous vendre des produits.
                </p>

            </div>

            <div>
                <h2>QUESTIONS ET COORDONNÉES</h2>
                <p>
                    Si vous souhaitez: accéder à, corriger, modifier ou supprimer toute information personnelle que nous
                    avons à votre sujet, déposer une plainte, ou si vous souhaitez simplement avoir plus d’informations,
                    contactez nous à anneso.naturelle@gmail.com.
                </p>
            </div>
        </div>
    )
}

export default PolitiqueConfidentialite;