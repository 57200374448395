import imgAccueil from "../../Images/img_accueil.png";
import braceletAccueil from "../../Images/bracelet_accueil.jpeg";
import "./HomePage.scss";
import {useEffect, useState} from "react";
import ProductItem from "../../components/ProductItem/ProductItem";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

function HomePage() {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Accueil";
        const get1_example_of_each = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}products/1-example-of-each`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    },
                });
                const products = await response.json();
                setProducts(products);
            } catch (error) {
                console.log(error)
            }
        }
        get1_example_of_each();
    }, []);

    const goToCollectionsPage = (categorie) => {
        sessionStorage.setItem('categorieFiltre', categorie);
        navigate("/collections");
    }

    return (
        <>
            <div className="first-page-accueil-body">
                <div className={"div-first-image"}>
                    <h1 style={{
                        color: "#fff",
                        filter: "drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.75))",
                        fontSize: "60px"
                    }}>Anne'so
                        Naturelle</h1>
                    <h2 style={{color: "#fff", filter: "drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.75))"}}>Chaque bijou
                        raconte une histoire
                    </h2>
                    <button style={{
                        textAlign: "center",
                        maxWidth: "15em",
                        margin: "5em 0 0 0",
                        borderRadius: "20px",
                        padding: "1.2em 2em 1.2em 2em",
                        fontWeight: "bold",
                        fontSize: "16px",
                        backgroundColor: "#ae445a",
                        color: "#fff",
                        cursor: "pointer",
                    }}
                            onClick={() => navigate("/collections")}>Découvrir nos produits
                    </button>
                </div>
                <h1 style={{
                    textAlign: "center",
                    margin: "3em 0 0 0",
                    textDecoration: "underline",
                    fontFamily: "Playfair Display, serif",
                    textUnderlineOffset: '0.5em',
                    fontWeight: "bold",
                    fontSize: "40px"
                }}>Nos catégories de
                    produits</h1>
                <div className={"div-categories-1"}>
                    <div style={{display: "flex", flexDirection: "column", margin: "0 0 2em 0"}}>
                        <img
                            src={"https://res.cloudinary.com/dc1p20eb2/image/upload/v1713633968/Anne%27so%20Naturelle/Image/Bjioux.jpg"}
                            width={300}
                            height={350} onClick={() => goToCollectionsPage("Bijoux")} alt={'image categorie Bijoux'}/>
                        <p style={{fontSize: "1.5em", textAlign: "center"}}>Bijoux et accessoires</p>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", margin: "0 0 0 0"}}>
                        <img
                            src={"https://res.cloudinary.com/dc1p20eb2/image/upload/v1713633968/Anne%27so%20Naturelle/Image/zen.jpg"}
                            width={300}
                            height={350} onClick={() => goToCollectionsPage("Zen")} alt={'image categorie Zen'}/>
                        <p style={{fontSize: "1.5em", textAlign: "center"}}>Zen</p>
                    </div>

                </div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", margin: "0 0 3em 0"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <img
                            src={"https://res.cloudinary.com/dc1p20eb2/image/upload/v1713633968/Anne%27so%20Naturelle/Image/Bien_etre.jpg"}
                            width={300} height={350} onClick={() => goToCollectionsPage("Bien etre")}
                            alt={'image categorie Bien être'}/>
                        <p style={{fontSize: "1.5em", textAlign: "center"}}>Bien être</p>
                    </div>
                </div>
                <h2 style={{textAlign: "center"}}>Exemples de produits</h2>
                <div className={"div-example-of-products"}>
                    {products.map(({
                                       id,
                                       covers,
                                       name,
                                       price,
                                       category,
                                       secondary_category,
                                       description,
                                       pierres
                                   }) => (
                        <div key={id} className="div-product">
                            <ProductItem
                                id={id}
                                covers={covers}
                                name={name}
                                price={price}
                                description={description}
                                category={category}
                                secondary_category={secondary_category}
                                pierres={pierres}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default HomePage;
