import {MapContainer, Marker, TileLayer, Popup, useMap} from "react-leaflet";
import {useEffect, useState} from "react";
import 'leaflet/dist/leaflet.css';
import './openStreetMap.scss';
import {Icon} from "leaflet";
import isNumeric from "validator/es/lib/isNumeric";
import MarkerClusterGroup from "react-leaflet-cluster";
import Cookies from "js-cookie";
import {Loader} from "../Loader";

function FlyMapTo({latlng}) {
    const map = useMap();

    useEffect(() => {
        if (!latlng || latlng.length !== 2) {
            return
        }
        console.log(latlng)
        let latitude = latlng[0].replace(',', '.');
        let longitude = latlng[1].replace(',', '.');
        map.flyTo([latitude, longitude], 18)
    }, [latlng])
    return null
}

function OpenStreetMap({codePostal, relayPoints, selectedPoint, latlng, setlng, setSelectedPoint, loading}) {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    const customIcon = new Icon({
        iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
        iconSize: [38, 38],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    function formatHour(hour) {
        const paddedHour = hour.padStart(4, '0'); // Ajoute des zéros non significatifs si nécessaire
        const formattedHour = `${paddedHour.slice(0, 2)}h${paddedHour.slice(2)}`;
        return formattedHour;
    }

    useEffect(() => {
        async function fetchCoordinates() {
            try {
                const response = await fetch(`https://nominatim.openstreetmap.org/search?${isNumeric(codePostal) ? "postalcode" : "city"}=${codePostal}&format=json&addressdetails=1`);
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des coordonnées');
                }
                const data = await response.json();
                if (data && data.length > 0) {
                    const {lat, lon} = data[0];
                    setLatitude(lat);
                    setLongitude(lon);
                    setlng(null)
                } else {
                    throw new Error('Aucune coordonnée trouvée pour ce code postal');
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchCoordinates();
    }, [codePostal]);

    return (
        <div>
            <h1>Point relais Mondial Relay</h1>
            {latitude !== null && longitude !== null && loading === false ? (
                <MapContainer center={[latitude, longitude]} zoom={13} scrollWheelZoom={true}>
                    <FlyMapTo latlng={latlng}/>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MarkerClusterGroup chunckedLoading>
                        {
                            relayPoints ? (
                                relayPoints.map((point, index) => {
                                    {
                                        return (
                                            point.PointRelais_Details.map((detail, index) => {
                                                let latitude = detail.Latitude[0].replace(',', '.');
                                                let longitude = detail.Longitude[0].replace(',', '.');
                                                return (
                                                    <Marker key={index}
                                                            position={[latitude, longitude]}
                                                            icon={customIcon}
                                                            eventHandlers={{
                                                                click: (e) => {
                                                                    Cookies.set('MondialRelayPoint', detail.Num[0]);
                                                                    setSelectedPoint(detail.Num[0]);
                                                                    Cookies.set('MondialRelayPointDetails', JSON.stringify(detail));
                                                                },
                                                            }}
                                                    >
                                                        <Popup>
                                                            <div className={"div-popup-marker"}>
                                                                <img style={{maxWidth: "100%"}}
                                                                     src={detail.URL_Photo[0]}
                                                                     alt="Photo du point relais"/>
                                                                <h2>{detail.LgAdr1[0]}</h2>
                                                                <p>{detail.LgAdr3[0]}</p>
                                                                <p>{detail.CP[0]}</p>
                                                                <p>{detail.Ville[0]}</p>
                                                            </div>
                                                            <div>
                                                                <h3>Horaires d'ouverture</h3>
                                                                <ul>
                                                                    <li>Lundi: {detail.Horaires_Lundi[0].string.filter(hour => hour !== "").map(formatHour).join(', ')}</li>
                                                                    <li>Mardi: {detail.Horaires_Mardi[0].string.filter(hour => hour !== "").map(formatHour).join(', ')}</li>
                                                                    <li>Mercredi: {detail.Horaires_Mercredi[0].string.filter(hour => hour !== "").map(formatHour).join(', ')}</li>
                                                                    <li>Jeudi: {detail.Horaires_Jeudi[0].string.filter(hour => hour !== "").map(formatHour).join(', ')}</li>
                                                                    <li>Vendredi: {detail.Horaires_Vendredi[0].string.filter(hour => hour !== "").map(formatHour).join(', ')}</li>
                                                                    <li>Samedi: {detail.Horaires_Samedi[0].string.filter(hour => hour !== "").map(formatHour).join(', ')}</li>
                                                                    <li>Dimanche: {detail.Horaires_Dimanche[0].string.filter(hour => hour !== "").map(formatHour).join(', ')}</li>
                                                                </ul>
                                                            </div>
                                                        </Popup>
                                                    </Marker>
                                                );
                                            })
                                        );
                                    }
                                })) : null
                        }
                    </MarkerClusterGroup>
                </MapContainer>
            ) : <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Loader/>
            </div>}
        </div>
    );
}

export default OpenStreetMap;