import {useEffect, useState} from "react";
import ProductItem from "../../components/ProductItem/ProductItem";
import Categories from "../../components/Categories/Categories";
import "./ShoppingList.css";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faFilter,
    faXmark,
    faSliders,
} from "@fortawesome/free-solid-svg-icons";
import {Loader} from "../../utils/Loader";
import NavigationProductList from "../../components/NavigationProductList/NavigationProductList";
import {useFiltre} from "../../utils/FiltreContext";
import Cookies from "js-cookie";

function ShoppingList({setproductList, productList}) {
    const [nameTable, setNameTable] = useState(productList);
    const [isDataLoading, setDataLoading] = useState(false);

    const {
        activeCategory,
        setActiveCategory,
        triageActive,
        setActiveTriage,
        minPriceForThisCategory,
        setminPriceForThisCategory,
        maxPriceForThisCategory,
        setmaxPriceForThisCategory,
        filtreValider,
        setFiltreValider,
        minPriceContext,
        setMinPriceContext,
        maxPriceContext,
        setMaxPriceContext,
        colorsContext,
        pierresContext
    } = useFiltre();

    const setMinAndMaxPrice = (productList) => {
        const minPrice = Math.min(...productList.filter(product => product.onSale).map(product => product.price));
        const maxPrice = Math.max(...productList.filter(product => product.onSale).map(product => product.price));
        sessionStorage.setItem("filtrePrixBaseCategorie", JSON.stringify([minPrice, maxPrice]));
        setmaxPriceForThisCategory(maxPrice);
        setminPriceForThisCategory(minPrice);
    };

    const resetFilters = () => {
        sessionStorage.removeItem("couleursChoisies");
        sessionStorage.removeItem("pierresChoisies");
    }

    useEffect(() => {
        setNameTable(productList);
        setMinAndMaxPrice(productList)
        trie();
    }, [productList]);

    useEffect(() => {
        document.title = "Collections";
        if (
            sessionStorage.getItem("categorieFiltre") &&
            sessionStorage.getItem("categorieFiltre") !== null &&
            sessionStorage.getItem("categorieFiltre") !== ""
        ) {
            const fetchProductsFromOneCategory = async () => {
                try {
                    setDataLoading(true);
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}products/category/${sessionStorage.getItem(
                            "categorieFiltre"
                        )}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );
                    const products = await response.json();
                    setproductList(products);
                } catch (error) {
                    console.error("Erreur de connexion au serveur:", error);
                } finally {
                    setDataLoading(false);
                }
            };
            fetchProductsFromOneCategory();
        } else {
            const fetchData = async () => {
                try {
                    setDataLoading(true);
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}products`
                    );
                    const productList = await response.json();
                    setproductList(productList);
                    resetFilters();
                } catch (error) {
                    console.error(error);
                } finally {
                    setDataLoading(false);
                }
            };
            fetchData().then(() => {
                setMinAndMaxPrice(productList);
            });
        }
    }, []);

    useEffect(() => {
        const filtrePrix = JSON.parse(sessionStorage.getItem("filtrePrix"));
        const filtrePrixBaseCategorie = JSON.parse(sessionStorage.getItem("filtrePrixBaseCategorie"));

        if (filtrePrix && filtrePrixBaseCategorie && filtrePrix[0] === filtrePrixBaseCategorie[0] && filtrePrix[1] === filtrePrixBaseCategorie[1]) {
            sessionStorage.setItem("filtrePrix", JSON.stringify([minPriceForThisCategory, maxPriceForThisCategory]));
        }
    }, [minPriceForThisCategory, maxPriceForThisCategory]);

    const trie = () => {
        console.log('testttttttttttttttttttttttttttttttttttt');
        const nameTable = [...productList];
        let filteredData = [];
        // Tableau fictif de produits filtrés par prix
        {
            JSON.parse(sessionStorage.getItem('filtrePrix')) ? (
                filteredData = nameTable.filter(
                    (item) => item.price >= JSON.parse(sessionStorage.getItem('filtrePrix'))[0] && item.price <= JSON.parse(sessionStorage.getItem('filtrePrix'))[1])) : (
                filteredData = nameTable.filter(
                    (item) => item.price >= JSON.parse(sessionStorage.getItem('filtrePrixBaseCategorie'))[0] && item.price <= JSON.parse(sessionStorage.getItem('filtrePrixBaseCategorie'))[1]))
        }
        console.log('filteredData', filteredData)

        let sortedTable;
        const sortFunctions = {
            croissant: (a, b) => a.price - b.price,
            decroissant: (a, b) => b.price - a.price,
            nom: (a, b) => (a.name > b.name ? 1 : -1),
        };
        console.log('pierresContext', pierresContext);
        console.log('colorsContext', colorsContext);
        if (pierresContext.length > 0 && colorsContext.length > 0) {
            console.log('------------------- pierres et couleurs')
            sortedTable = filteredData.filter((item) =>
                item.color.includes(colorsContext[0]) && item.pierres.includes(pierresContext[0])
            ).sort(sortFunctions[triageActive]);
        } else if (pierresContext.length > 0) {
            console.log('------------------- pierres')
            sortedTable = filteredData.filter((item) =>
                pierresContext.some((pierre) => item.pierres.includes(pierre))
            ).sort(sortFunctions[triageActive]);
        } else if (colorsContext.length > 0 && sessionStorage.getItem('couleursChoisies')) {
            console.log('------------------- couleurs');
            sortedTable = filteredData.filter((item) =>
                colorsContext.some((color) => item.color.includes(color))
            ).sort(sortFunctions[triageActive]);
        } else {
            console.log('------------------- puteeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
            sortedTable = filteredData.sort(sortFunctions[triageActive]);
        }
        setNameTable(sortedTable);
    };

    useEffect(() => {
        if (filtreValider) {
            if (minPriceContext === 0 && maxPriceContext === 0) {
                setMinPriceContext(minPriceForThisCategory);
                setMaxPriceContext(maxPriceForThisCategory);
            }
            trie();
            setFiltreValider(false);
        }
    }, [filtreValider]);

    return (
        <div className="lmj-shopping-list">
            <div className="div-categories-plus-products-list">
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    margin: "1em 0 0 0",
                    maxHeight: "8em",
                    zIndex: "12"
                }}>
                    <div>

                        <NavigationProductList setProductList={setproductList} setActiveCategory={setActiveCategory}
                                               activeCategory={activeCategory}/>
                    </div>
                </div>
                {isDataLoading ? (
                    <div className="loader-div-shopping-list">
                        <Loader/>
                    </div>
                ) : (
                    <ul className="lmj-plant-list">
                        {nameTable.length > 0 ? (
                            nameTable.map(({
                                               id,
                                               covers,
                                               name,
                                               price,
                                               category,
                                               secondary_category,
                                               description,
                                               pierres,
                                               onSale
                                           }) => (
                                (!activeCategory || activeCategory === category || activeCategory === secondary_category) && (onSale) ? (
                                    <div key={id} className="div-product">
                                        <ProductItem id={id} covers={covers} name={name} price={price}
                                                     description={description} category={category}
                                                     secondary_category={secondary_category} pierres={pierres}/>
                                    </div>
                                ) : null
                            ))
                        ) : (
                            <h3 style={{textAlign: "center", height: "65dvh"}}>Aucun produit correspondant à vos
                                critères</h3>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default ShoppingList;
