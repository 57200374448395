import {useEffect} from "react";
import Cookies from "js-cookie";

function AccountActiveOrNot({setAccountActive}) {
    useEffect(() => {
        const verifyIfAccountIsActive = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}auth/verify-token-JWT-account-activated`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${Cookies.get('auth_token')}`
                    }
                });
                const data = await response.json();
                console.log(data.active);
                setAccountActive(data.active);
            } catch (error) {
                console.error(error);
            }
        }
        verifyIfAccountIsActive();
    }, []);
}

export default AccountActiveOrNot;