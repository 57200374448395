import Cookies from "js-cookie";

function VerifyRoleInJWTtoken() {
    const verifyToken = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}auth/verify-token-JWT-role`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("auth_token")}`,
            },
        });
        return response.ok;
    }
    return verifyToken();
}

export default VerifyRoleInJWTtoken