import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram,
    faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

function Footer() {
    const navigate = useNavigate();

    const handleClickContact = () => {
        navigate("Contact");
    };

    const handleClickLinkFooter = (link) => {
        navigate(link);
        window.scrollTo(0, 0);
    }

    const handleClickSocials = (redirection) => {
        redirection === "facebook"
            ? window.open(
                "https://www.facebook.com/profile.php?id=100075994402255",
                "_blank"
            )
            : redirection === "instagram"
                ? window.open(
                    "https://www.instagram.com/anneso2273/?igshid=OGQ5ZDc2ODk2ZA%3D%3D",
                    "_blank"
                )
                : window.open("https://www.tiktok.com/@anneso2273", "_blank");
    };
    return (
        <footer className="lmj-footer">
            <div className="lmj-footer-elem">
                <div className={"div-main-elements-footer"}>
                    <div className="footer-text" style={{padding: "2em"}}>
                        <h2 style={{textDecoration: "underline", textUnderlineOffset: '0.2em'}}>Chez Anne'so
                            Naturelle</h2>
                        <p style={{lineHeight: "1.5em", cursor: "auto"}}>Bienvenue chez Anne'so Naturelle, où chaque
                            bijou, conçu avec
                            soin, rayonne d'authenticité. Découvrez nos bracelets faits main, empreints d'énergie
                            naturelle. Chaque création reflète la beauté simple et la tranquillité. Plongez dans notre
                            univers où la créativité rencontre le bien-être.</p>
                        {/*
                        <div className="div-lien-juridique">
                            <a href="/mentions-legales" style={{color: "#fff"}}>
                                Mentions Légales
                            </a>
                            <p style={{margin: "0"}}>-</p>
                        </div>*/}
                    </div>
                    <div className={"div-menu-plus-informations-footer"}>
                        <div className={"div-menu-footer"}>
                            <h3 style={{textDecoration: "underline", textUnderlineOffset: '0.2em'}}>Menu</h3>
                            <p className={"liens-footer"} onClick={() => handleClickLinkFooter("/")}>Accueil</p>
                            <p className={"liens-footer"} onClick={() => handleClickLinkFooter("/collections")}>Nos
                                Collections</p>
                            <p className={"liens-footer"}
                               onClick={() => handleClickLinkFooter("/profil/infos-persos")}>Mon compte</p>
                            <p className={"liens-footer"} onClick={() => handleClickLinkFooter("/panier")}>Mon
                                panier</p>
                            <p className={"liens-footer"} onClick={() => handleClickLinkFooter("/apropos")}>Notre
                                histoire</p>
                            <p className={"liens-footer"} onClick={() => handleClickLinkFooter("/contact")}>Contact</p>
                        </div>
                        <div className={"div-informations-footer"}>
                            <h3 style={{textDecoration: "underline", textUnderlineOffset: '0.2em'}}>Informations</h3>
                            <p className={"liens-footer"}
                               onClick={() => handleClickLinkFooter("/informations/conditions-generales-ventes-et-utilisations")}
                               style={{maxWidth: "75%"}}>Conditions générales de vente et d'utilisation</p>
                            <p className={"liens-footer"}
                               onClick={() => handleClickLinkFooter("/informations/retractation")}>Droit de
                                rétractation</p>
                            <p className={"liens-footer"}
                               onClick={() => handleClickLinkFooter("/informations/politique-confidentialite")}>Politique
                                de
                                confidentialité</p>
                            <p className={"liens-footer"}
                               onClick={() => handleClickLinkFooter("/informations/mentions-legales")}>Mentions
                                Légales</p>
                            <p className={"liens-footer"}
                               onClick={() => handleClickLinkFooter("/informations/paiements-securise")}>Paiement
                                sécurisé</p>
                        </div>
                    </div>
                </div>
                <div className="icon-socials">
                    <FontAwesomeIcon
                        className="iconSocial"
                        icon={faFacebook}
                        onClick={() => handleClickSocials("facebook")}
                    />
                    <FontAwesomeIcon
                        className="iconSocial"
                        icon={faInstagram}
                        onClick={() => handleClickSocials("instagram")}
                    />
                    <FontAwesomeIcon
                        className="iconSocial"
                        icon={faTiktok}
                        onClick={() => handleClickSocials("tiktok")}
                    />
                </div>
            </div>
            <p style={{textAlign: "center"}}>© 2024, Anne'so Naturelle - anneso.naturelle@gmail.com</p>
        </footer>
    );
}

export default Footer;
