import Cookies from "js-cookie";
import {useEffect} from "react";

const IsAdmin = ({setAdmin}) => {
    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}auth/verify-token-JWT-role`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("auth_token")}`,
                    },
                });
                const isAdmin = await response.json();
                isAdmin === true ? setAdmin(true) : setAdmin(false);
            } catch (error) {
                console.log(error);
            }
        };
        verifyToken();
    }, []);

    return null;
};

export default IsAdmin;