import Cookies from "js-cookie";
import Swal from "sweetalert2";
import sendVerificationEmail from "../../services/SendVerificationEmail";

const generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const uniqueId = `${timestamp}`;
    return uniqueId;
};

export const fetchUserByEmail = (googleToken, navigate) => {
    if (!googleToken) return;
    console.log(googleToken)
    try {
        const getUserByEmail = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}users/${googleToken.email}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.ok) {
                const user = await response.json();
                const loginData = {
                    email: googleToken.email,
                    password: '',
                };
                if (user.userWithThisEmail === true && user.signInWithGoogle === true) {
                    // Si l'utilisateur existe déjà dans la base de données et qu'il s'est connecté avec Google
                    // login l'user
                    const response = await fetch(`${process.env.REACT_APP_API_URL}auth/login`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(loginData),
                    });

                    if (response.ok) {
                        const data = await response.json();
                        Cookies.set("auth_token", data.token, {expires: 7});
                        const userName = `${googleToken.given_name}`;
                        Cookies.set("name", userName, {expires: 7})
                        navigate("/profil/infos-persos", {
                            state: {
                                nom: user.nom,
                                prenom: user.prenom,
                                adresseEmail: user.adresseEmail,
                                numeroTel: user.numeroTel,
                                civilite: user.civilite
                            },
                        });
                    } else if (response.status === 400) {
                        Swal.fire({
                            title: "Compte non activé",
                            text: "Vérifié vos emails pour activer votre compte",
                            icon: "error",
                            confirmButtonText: "Ok",
                        });
                    } else {
                        console.error("Erreur lors de la création du compte");
                    }
                    // Si l'utilisateur existe déjà dans la base de données mais qu'il ne s'est pas connecté avec Google
                } else if (user.userWithThisEmail === true && user.signInWithGoogle === false) {
                    Swal.fire({
                        title: "Compte déjà existant avec cette adresse email.",
                        text: "Veuillez vous connecter avec votre adresse email et mot de passe, ou bien réinitialiser votre mot de passe.",
                        icon: "error",
                        confirmButtonText: "Ok",
                    });
                }
                // Si l'utilisateur n'existe pas dans la base de données
            } else {
                const newId = generateUniqueId();
                const newUser = {
                    id: newId,
                    nom: googleToken.family_name,
                    prenom: googleToken.given_name,
                    adresseEmail: googleToken.email,
                    signInWithGoogle: true,
                    role: "client",
                    accountActivated: false,
                };
                const response = await fetch(`${process.env.REACT_APP_API_URL}auth/register`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(newUser),
                });

                if (response.ok) {
                    await sendVerificationEmail(googleToken.email);
                    const data = await response.json();
                    Cookies.set("auth_token", data.token, {expires: 7});
                    const userName = `${googleToken.given_name}`;
                    Cookies.set("name", userName, {expires: 7})
                    navigate("/profil/infos-persos");
                    Swal.fire({
                        title: "Compte créé avec succès.",
                        text: "Vous pouvez maintenant accéder à votre compte.",
                        icon: "success",
                        confirmButtonText: "Ok",
                        toast: true,
                        timer: 4000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        position: "top-end",
                    });
                } else {
                    console.error("Erreur lors de la création du compte");
                }
            }
        };
        getUserByEmail();
    } catch (error) {
        console.error(error);
    }
}
