import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import Swal from "sweetalert2";
import Cookies from "js-cookie";


function ActivateAccount() {
    const {token} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const activateAccount = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}auth/activate-account/${token}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                const data = await response.json();
                console.log(data.token)
                if (response.ok) {
                    Swal.fire({
                        title: "Compte activé.",
                        text: "Vous allez être redirigé vers la page de votre compte.",
                        icon: "success",
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/profil/infos-persos");
                        }
                    });
                    Cookies.set("auth_token", data.token);
                } else {
                    console.error("Error while activating account");
                }
            } catch (error) {
                console.error("Error while activating account");
            }
        };
        activateAccount();
    }, [token]);
}

export default ActivateAccount;