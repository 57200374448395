import Cookies from "js-cookie";

function SendEmailOrderRefund(user, stripePaymentCancel, raison) {
    const sendEmail = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}mail/email-refund-client`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify({
                        raison: raison,
                        remboursement: stripePaymentCancel === "Remboursée" ? true : false,
                        email: user.adresseEmail,
                    }),
                },
            );
            if (response.ok) {
                const data = await response.json();
                console.log(data);
            } else {
                console.log(response);
            }
        } catch (error) {
            console.error(error);
        }
    }
    sendEmail();
}

export default SendEmailOrderRefund;