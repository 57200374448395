import React, {useState} from 'react';
import './ImagesCarrousel.scss';

function ImageCarousel({images, setSelectedImage}) {

    return (
        <div>
            <div className={'div-carrousel-images'}>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={"images du produit collections"}
                        onClick={() => setSelectedImage(image)}
                        style={{width: '100px', cursor: 'pointer'}}
                    />
                ))}
            </div>
        </div>
    );
}

export default ImageCarousel;