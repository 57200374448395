import Cookies from "js-cookie";

const UpdateOrCreateAnewStripeProductId = async (produit, idPrixStripe, setIdPrixStripe) => {
    try {
        console.log(idPrixStripe)
        const response = await fetch(`${process.env.REACT_APP_API_URL}products/create-stripe-product`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("auth_token")}`,
            },
            body: JSON.stringify({product: produit, priceId: idPrixStripe}),
        });
        const newIdPrixStripe = await response.json();
        setIdPrixStripe(newIdPrixStripe);
    } catch (e) {
        console.log(e);
    }
}

export default UpdateOrCreateAnewStripeProductId;