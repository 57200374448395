import OrderProgress from "../../components/OrderProgress/OrderProgress";
import './CheckoutDelivery.css';
import {useEffect, useState} from "react";
import CardAdresses from "../../components/CardAdresses/CardAdresses";
import Cookies from "js-cookie";
import {faArrowLeft, faLocationArrow} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import GoingBack from "../../components/GoingBack/GoingBack";
import OpenStreetMap from "../../utils/Maps/OpenStreetMap";
import isNumeric from "validator/es/lib/isNumeric";
import ButtonDeconnect from "../../components/Button Deconnect/ButtonDeconnect";
import AccountActiveOrNot from "../../components/AccountActiveOrNot/AccountActiveOrNot";
import {Loader} from "../../utils/Loader";
import TextAccountNotActiveWithDeconnectButton
    from "../../components/AccountActiveOrNot/TextAccountNotActiveWithDeconnectButton";

function CheckoutDelivery() {
    const [adresses, setAdresses] = useState([]);
    const [adresseId, setidAdresseDomicileChoisie] = useState("");
    const [inputErreurChoixLivraison, setInputErreurChoixLivraison] = useState("");
    const [inputErreurCodePostal, setInputErreurCodePostal] = useState("");
    const [codePostal, setCodePostal] = useState("");
    const [relais, setRelais] = useState(null);
    const [ville, setVille] = useState("");
    const navigate = useNavigate();
    const [selectedPoint, setSelectedPoint] = useState(Cookies.get('MondialRelayPoint'));
    const [latlng, setLatlng] = useState(null);
    const [accountActive, setAccountActive] = useState(null);
    const [loading, setLoading] = useState(false);
    const resetCookiesLivraison = () => {
        Cookies.remove('MondialRelayPoint');
        Cookies.remove('MondialRelayPointDetails');
        Cookies.remove('adresseId');
    }

    useEffect(() => {
        Cookies.set('MondialRelayPoint', selectedPoint, {expires: 0.1});
    }, [selectedPoint]);

    useEffect(() => {
        window.scrollTo(0, 0);
        resetCookiesLivraison();
        const cartItems = JSON.parse(localStorage.getItem("cart"));
        if (!cartItems || cartItems.length === 0) {
            navigate("/panier");
        }
        document.title = "Choix Livraison";
        try {
            window.scroll(0, 0);
            const getAllAdressesForAclient = async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}adresses/`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${Cookies.get("auth_token")}`,
                        },
                    }
                );
                const adresses = await response.json();
                setAdresses(Object.values(adresses));
            }
            getAllAdressesForAclient();
        } catch (error) {
            console.log(error);
        }
    }, []);

    const validerChoixLivraison = () => {
        if (!selectedPoint && !adresseId) {
            setInputErreurChoixLivraison("Veuillez choisir une adresse à domicile ou un point relais.");
        } else if (!Cookies.get('MondialRelayPoint') && Cookies.get('adresseId')) {
            Cookies.remove('MondialRelayPoint');
            navigate("/checkout/payment");
        } else if (Cookies.get('MondialRelayPoint') && !Cookies.get('adresseId')) {
            Cookies.remove('adresseId');
            navigate("/checkout/payment");
        }
    }

    const fetchRelais = async () => {
        setRelais(null);
        if ((!isNumeric(codePostal) && codePostal.length > 2) || (isNumeric(codePostal) && codePostal.length === 5)) {
            setInputErreurCodePostal("");
        } else if (codePostal.length !== 5) {
            setInputErreurCodePostal("Veuillez renseigner un code postal ou une ville valide.");
            return;
        }
        Cookies.remove('adresseId');
        setLoading(true); // Assurez-vous de montrer que le chargement a commencé

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}mondialRelay/${codePostal}`, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                setInputErreurCodePostal("Aucun point relais trouvé pour ce code postal ou cette ville");
                setLoading(false);
                return;
            }

            const relais = await response.json();

            if (relais.length === 0) {
                setInputErreurCodePostal("Aucun point relais trouvé pour ce code postal ou cette ville");
            } else {
                setRelais(relais);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des points relais:", error);
            setInputErreurCodePostal("Une erreur est survenue lors de la récupération des points relais");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        Cookies.set('adresseId', adresseId, {expires: 0.1});
    }, [adresseId]);

    const goingBackToMainDelivery = () => {
        console.log("going back to main delivery")
        resetCookiesLivraison();
        setRelais(null);
    }

    const handleFetchRelais = (e) => {
        if (e.key === "Enter") {
            setLoading(true);
            fetchRelais();
        }
    };

    return (
        <>
            <AccountActiveOrNot setAccountActive={setAccountActive}/>
            {accountActive === false && accountActive !== null ? (
                <TextAccountNotActiveWithDeconnectButton/>
            ) : (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 0 2em 0",
                }}>
                    <div
                        style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        <a href={"/"}>
                            <img
                                src={"https://res.cloudinary.com/dc1p20eb2/image/upload/v1711445140/Anne%27so%20Naturelle/logo/logo.png"}
                                alt={"logo anneso-naturelle"} height={125} width={200}/>
                        </a>
                    </div>
                    <div style={{margin: "0 0 2em 0"}}>
                        <GoingBack/>
                    </div>
                    <OrderProgress/>
                    {relais &&
                        <div
                            style={{margin: "3em 0 0 1em", display: "flex", flexDirection: "row", alignItems: "center"}}
                            onClick={() => goingBackToMainDelivery()}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{color: "black", cursor: "pointer"}}/>
                            <p style={{margin: "0 0 0 1em"}}>Retour choix Livraison</p>
                        </div>
                    }
                    <div className={"div-main-checkout-delivery"}>
                        <div className={"div-left-main-div-checkout-delivery"}>
                            <div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <h3>Livraison en point relais</h3>
                                    <img
                                        src={"https://res.cloudinary.com/dc1p20eb2/image/upload/v1710505083/Anne%27so%20Naturelle/Image/image_point_relais.png"}
                                        alt={'logo livraison point relais anneso-naturelle'}/>
                                    <h4>3.90 € de frais de port</h4>
                                    <hr style={{borderTop: "1px solid black", width: "80%", margin: "0"}}/>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column"
                                }}>
                                    <p style={{textAlign: "center"}}>Chercher un point relais</p>
                                    <div className={"input-code-postal-relais"}>
                                        <input type={"text"} className={"input-login"} value={codePostal}
                                               onChange={(e) => setCodePostal(e.target.value)}
                                               placeholder="Code Postal ou Ville"
                                               onKeyUp={(e) => handleFetchRelais(e)}
                                        />
                                        <FontAwesomeIcon icon={faLocationArrow}
                                                         style={{color: "black", cursor: "pointer"}}
                                                         id={"icon-input-code-postal-relais"}
                                                         onClick={() => {
                                                             fetchRelais();
                                                             setLoading(true)
                                                         }}/>
                                    </div>
                                </div>
                                {inputErreurCodePostal !== "" &&
                                    <p style={{
                                        color: "red",
                                        textAlign: "center",
                                        margin: "0"
                                    }}>{inputErreurCodePostal}</p>}
                            </div>
                            <div className={'panel-relay-point'} style={{display: relais ? 'block' : 'none'}}>
                                {relais ? (
                                    relais.map((relai, index) => (
                                        <div key={index}>
                                            {relai.PointRelais_Details.map((detail, index) => (
                                                <div key={index} style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "start",
                                                    border: "1px solid black",
                                                    flexDirection: "column",
                                                    margin: "1em 0 1em 0",
                                                    borderRadius: "10px",
                                                    cursor: "pointer",
                                                    backgroundColor: Cookies.get('MondialRelayPoint') === detail.Num[0] ? "#96154a" : "white",
                                                    color: Cookies.get('MondialRelayPoint') === detail.Num[0] ? "#fff" : "#000"
                                                }} onClick={() => {
                                                    Cookies.set('MondialRelayPoint', detail.Num[0]);
                                                    Cookies.set('MondialRelayPointDetails', JSON.stringify(detail));
                                                    setSelectedPoint(detail.Num[0]);
                                                    setLatlng([detail.Latitude[0], detail.Longitude[0]]);
                                                }}>
                                                    <p style={{
                                                        color: selectedPoint === detail.Num[0] ? "#fff" : "#000",
                                                    }}>{detail.LgAdr1[0]}</p>
                                                    <p>{detail.LgAdr3[0]}</p>
                                                    <div style={{display: "flex", flexDirection: "row"}}>
                                                        <p style={{margin: "1em 1em 0 0"}}>{detail.CP[0]}</p>
                                                        <p>{detail.Ville[0]}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        </div>
                        {relais &&
                            <OpenStreetMap codePostal={codePostal} relayPoints={relais} selectedPoint={selectedPoint}
                                           latlng={latlng} setlng={setLatlng} setSelectedPoint={setSelectedPoint}
                                           loading={loading}/>
                        }
                        {!relais &&
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                maxWidth: "100%"
                            }}>
                                <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                                    <h3>Livraison à domicile</h3>
                                    <img width={150} height={150}
                                         src={"https://res.cloudinary.com/dc1p20eb2/image/upload/v1710505531/Anne%27so%20Naturelle/Image/image_livraison_domicile.png"}
                                         alt={'logo livraison à domicile anneso-naturelle'}/>
                                    <h4>5.90€ de frais de port</h4>
                                </div>
                                <hr style={{borderTop: "1px solid black", width: "80%", margin: "0"}}/>
                                <p style={{textAlign: "center"}}>{adresses.length > 0 ? "Choisissez votre adresse de livraison." : "Vous n'avez pour le moment renseigner aucune adresse."}</p>

                                <div style={{
                                    maxWidth: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column"
                                }}>
                                    {adresses ?
                                        adresses.map((adresse) => {
                                            return (
                                                <>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        flexDirection: "row",
                                                        margin: "0 1em 0 1em",
                                                        maxWidth: "80%"
                                                    }}>
                                                        <input type={"radio"} value={adresse._id}
                                                               onChange={() => setidAdresseDomicileChoisie(adresse._id)}
                                                               checked={adresseId === adresse._id}
                                                               style={{margin: "0 1em 0 0"}}/>
                                                        <CardAdresses
                                                            adresseId={adresse._id}
                                                            nomPersonne={adresse.nomPersonne}
                                                            prenomPersonne={adresse.prenomPersonne}
                                                            adresse={adresse.adresse}
                                                            codePostal={adresse.codePostal}
                                                            pays={adresse.pays}
                                                            ville={adresse.ville}
                                                            complementAdresse={adresse.complementAdresse}
                                                            numTel={adresse.numTel}
                                                            setAdresses={setAdresses}
                                                            adresses={adresses}/>
                                                    </div>
                                                </>
                                            )
                                        })
                                        : null}
                                    {adresses === [] && <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        margin: "0.5em 0 0 0"
                                    }}>
                                        <hr style={{flexGrow: 1, width: '8.5em', height: "1px"}}/>
                                        <span style={{margin: '0 10px'}}>Ou</span>
                                        <hr style={{flexGrow: 1, width: '8.5em', height: "1px"}}/>
                                    </div>}

                                    <button style={{margin: "1em 0 0 2em", width: "75%"}} id={"bouton-ajout-adresse"}
                                            onClick={() => navigate("/profil/adresses/ajoutAdresse")}>Ajouter une
                                        adresse
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <button style={{margin: "1em 2em 0em 2em", backgroundColor: "#287834"}}
                                onClick={() => validerChoixLivraison()}>Valider le choix de livraison
                        </button>
                    </div>
                    {inputErreurChoixLivraison !== "" &&
                        <p style={{color: "red", textAlign: "center"}}>{inputErreurChoixLivraison}</p>}
                </div>
            )}
        </>
    )
}

export default CheckoutDelivery;
