function MentionsLegales() {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            margin: "1em"
        }}>
            <h1>Mentions Légales</h1>
            <h2>1. Informations Légales</h2>
            <p>
                Nom de l'entreprise : Anne'so Naturelle <br/>
                Forme Juridique : Auto-entrepreneur <br/>
                Numéro de téléphone : 07 50 99 44 83
                <br/>
                Numéro d'immatriculation : 844596205 <br/>
                Capital Social : 0 <br/>
            </p>
            <h2>2. Hébergement du site</h2>
            <p>
                Nom de l'hébergeur : Vercel <br/>
                Adresse : Vercel Inc.
                340 S Lemon Ave #4133
                Walnut, CA 91789
                USA <br/>
                Numéro de téléphone : pas publique <br/>
            </p>
            <h2>3. Protection des données personnelles</h2>
            <p>
                Conformément à la loi Informatique et Libertés du 6 janvier 1978, vous
                disposez d'un droit d'accès, de rectification et de supression des
                données vous concernant. Pour excercer ces droits , veuillez nous
                contacter à l'adresse suivante : anneso.naturelle@gmail.com
            </p>
            <h2>4. Collecte des données</h2>
            <p>
                Les informations personnelles collectées sur ce site sont destinés à
                Anne'so Naturelle. Elles font l'objet d'un traitement informatique et
                sont nécessaires à la gestion de votre commande et à nos relations
                commerciales. Anne'so Naturelle s'engage à ne pas divulguer ces
                informations à des tiers.
            </p>
            <h2>5. Cookies</h2>
            <p>
                Ce site utilise des cookies. Un cookie est un petit fichier texte
                stocké sur votre ordinateur qui permet d'analyser la fréquentation du
                site. Vous pouvez vous opposer à l'enregistrement de cookies en
                configurant les paramètres de votre navigateur.
            </p>
            <h2>6. Propriété intellectuelle</h2>
            <p>
                Tous les éléments du site de Anne'so Naturelle sont et restent la
                propriété intellectuelle et exclusive de Anne'so Naturelle. Toute
                reproduction, exploitation, rediffusion ou utilisation à quelque titre
                que ce soit, même partiellement, des éléments du site est strictement
                interdite.
            </p>
            <h2>7. Création de compte Client</h2>
            <p>
                La création d'un compte client sur le site de Anne'so Naturelle
                implique l'acceptation des présentes conditions générales. Anne'so
                Naturelle se réserve le droit de suspendre ou de fermer un compte
                utilisateur en cas de non-respect des conditions d'utilisation.
            </p>
            <h2>8. Litiges</h2>
            <p>
                En cas de litige, une solution amiable sera recherchée avant toute
                action judiciaire. À défaut, les tribunaux compétents seront ceux du
                lieu du siège social de Anne'so Naturelle.
            </p>
            <br/>
            <br/>
            <p id="last-p">
                Fait à Pordic, le 28/11/23.
            </p>
        </div>
    );
}

export default MentionsLegales;