import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import GoingBack from "../../components/GoingBack/GoingBack";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import IsAdmin from "../../components/Admin/IsAdmin";
import uploadImage from "../../services/UploadImage";
import formValid from "../../services/FormProductValid";
import {Loader} from "../../utils/Loader";

function AdminAddProduct() {
    const [nomProduit, setNomProduit] = useState("");
    const [categoryProduit, setCategoryProduit] = useState("");
    const [sousCategoryProduit, setSousCategoryProduit] = useState("");
    const [lienImageProduit, setLienImageProduit] = useState([]);
    const [prixProduit, setPrixProduit] = useState();
    const [descriptionProduit, setDescriptionProduit] = useState("");
    const [nomsPierres, setNomsPierres] = useState("");
    const [couleursPierres, setCouleursPierres] = useState("");
    const [formulaireCorrect, setFormulaireCorrect] = useState(false);
    const [readyToUploadImage, setReadyToUploadImage] = useState(false);
    const [idPrixStripe, setIdPrixStripe] = useState("");
    const [image, setImage] = useState([]);
    const [fileName, setFileName] = useState('Choisissez un ou plusieurs fichier(s)');
    let file = null;
    const [isAdmin, setIsAdmin] = useState(null);
    const navigate = useNavigate();
    const [productOnSale, setProductOnSale] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);

    useEffect(() => {
        if (isAdmin === null) {
            return;
        }
        !isAdmin && navigate("/");
    }, [isAdmin]);

    useEffect(() => {
        if (!lienImageProduit || lienImageProduit.length === 0) {
            return;
        }
        const addProductStripe = async () => {
            const product = {
                name: nomProduit,
                description: descriptionProduit,
                images: lienImageProduit,
                price: prixProduit
            }
            setDataLoading(true)
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}products/create-stripe-product`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("auth_token")}`,
                    },
                    body: JSON.stringify({product: product}),
                });
                const idPrixStripe = await response.json();
                setIdPrixStripe(idPrixStripe);
            } catch (e) {
                console.log(e);
                setDataLoading(false)
            }
        }
        addProductStripe();
    }, [lienImageProduit]);

    useEffect(() => {
        if (!idPrixStripe) {
            return;
        }
        const addProduct = () => {
            const timestamp = new Date().getTime();
            const uniqueId = `${timestamp}`;
            console.log(nomsPierres)
            console.log(couleursPierres)
            const produit = {
                id: uniqueId,
                name: nomProduit,
                category: categoryProduit,
                secondary_category: sousCategoryProduit,
                covers: lienImageProduit,
                price: prixProduit,
                description: descriptionProduit,
                idPrixStripe: idPrixStripe.idPrixStripe,
                pierres: nomsPierres && (categoryProduit === "Bijoux") ? nomsPierres : undefined,
                color: couleursPierres && (categoryProduit === "Bijoux") ? couleursPierres : undefined,
                onSale: productOnSale
            }
            console.log(produit)
            const addProductToDatabase = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}products/add`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${Cookies.get("auth_token")}`,
                        },
                        body: JSON.stringify(produit)
                    });
                    if (response.ok) {
                        Swal.fire({
                            text: "Produit ajouté avec succès",
                            icon: "success"
                        })
                    }
                } catch (e) {
                    console.log(e);
                    setDataLoading(false)
                } finally {
                    setDataLoading(false)
                }
            }
            addProductToDatabase();
        }
        addProduct();
    }, [idPrixStripe]);

    const handleImageUpload = event => {
        const files = Array.from(event.target.files);
        setImage(files);
        const fileNames = files.map(file => file.name).join('\n');
        setFileName(fileNames);
    };

    useEffect(() => {
        if (!lienImageProduit) {
            return;
        }

    }, [lienImageProduit]);

    return (
        <>
            <IsAdmin setAdmin={setIsAdmin}/>
            <GoingBack/>
            {!isAdmin ? null : (
                <div style={{
                    margin: "1em",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column"
                }}>
                    <h3>Formulaire d'ajout d'un produit sur le site</h3>
                    <div style={{display: "flex", flexDirection: "column", width: "70%"}}>
                        <label>Nom du produit</label>
                        <input style={{width: "80%", padding: "0.8em", margin: "0 0 0.9em 0"}} type="text"
                               onChange={(e) => setNomProduit(e.target.value)}
                        />
                        <label>Catégorie du produit</label>
                        <select value={categoryProduit} onChange={(e) => setCategoryProduit(e.target.value)}
                                style={{margin: "0 0 1em 0", width: "83%", height: "3em"}}>
                            <option value="">Choisit une catégorie</option>
                            <option value="Bijoux">Bijoux et accesoires</option>
                            <option value="Zen">Ambiance zen</option>
                            <option value="Bien etre">Bien être</option>
                        </select>

                        {(categoryProduit === "Bijoux") &&
                            <>
                                <label>Sous catégorie du produit</label>
                                <select value={sousCategoryProduit}
                                        onChange={(e) => setSousCategoryProduit(e.target.value)}
                                        style={{margin: "0 0 1em 0", width: "83%", height: "3em"}}>
                                    <option value="">Choisit une sous catégorie</option>
                                    <option value="Bracelet">Bracelet</option>
                                    <option value="Boucle Oreille">Boucles d'oreilles</option>
                                    <option value="Accessoire">Accessoire</option>
                                </select>
                            </>
                        }
                        {(categoryProduit === "Zen") &&
                            <>
                                <label>Sous catégorie du produit</label>
                                <select value={sousCategoryProduit}
                                        onChange={(e) => setSousCategoryProduit(e.target.value)}
                                        style={{margin: "0 0 1em 0", width: "83%", height: "3em"}}>
                                    <option value="">Choisit une sous catégorie</option>
                                    <option value="Lampe sel">Lampe de sel</option>
                                    <option value="Fontaine interieur">Fontaine d'intérieur</option>
                                    <option value="Diffuseur">Diffuseur d'ambiance</option>
                                    <option value="bruleur_parfum">Brûleur parfum</option>
                                    <option value="Statue">Statue</option>
                                </select>
                            </>
                        }
                        {(categoryProduit === "Bien etre") &&
                            <>
                                <label>Sous catégorie du produit</label>
                                <select value={sousCategoryProduit}
                                        onChange={(e) => setSousCategoryProduit(e.target.value)}
                                        style={{margin: "0 0 1em 0", width: "83%", height: "3em"}}>
                                    <option value="">Choisit une sous catégorie</option>
                                    <option value="Purifier">Purifier - Assaisir</option>
                                    <option value="Plateau recharge">Plateau de recharge</option>
                                    <option value="Orgonite">Orgonite</option>
                                </select>
                            </>
                        }
                        {categoryProduit === "Bijoux" &&
                            <>
                                <div style={{
                                    margin: "0 0 0 0",
                                    display: "flex",
                                    flexDirection: "column",
                                    maxWidth: "83%"
                                }}>
                                    <label>Nom des pierres - Exemple ( Diamant,Rubis,Cristal_De_Roche)</label>
                                    <input style={{height: "2em"}} type="text"
                                           onChange={(e) => setNomsPierres(e.target.value)}/>
                                </div>
                                <div style={{
                                    margin: "1em 0 1em 0",
                                    display: "flex",
                                    flexDirection: "column",
                                    maxWidth: "83%"
                                }}>
                                    <label>Nom des couleurs - Exemple ( Rouge,Gris_tacheté )</label>
                                    <input style={{height: "2em"}} type="text"
                                           onChange={(e) => setCouleursPierres(e.target.value)}/>
                                </div>
                            </>
                        }
                        <label>Prix Produit</label>
                        <input style={{width: "80%", padding: "0.8em", margin: "0 0 0.9em 0"}} type="number"
                               onChange={(e) => setPrixProduit(e.target.value)}/>
                        <label>Description du produit</label>
                        <input style={{width: "80%", padding: "0.8em", margin: "0 0 0.9em 0"}} type="text"
                               onChange={(e) => setDescriptionProduit(e.target.value)}/>
                        <div style={{margin: "0 0 1em 0"}}>
                            <input type="checkbox" checked={productOnSale} style={{width: "1em"}}
                                   onChange={(e) => setProductOnSale(e.target.checked)}/>
                            <label style={{margin: "0 0 0 0.2em"}}>Produit disponible à l'achat</label>
                        </div>
                        <p style={{margin: "0 0 0.5em 0"}}>Ajouter des photos :</p>
                        <input type="file" id="file" style={{display: 'none'}} multiple onChange={handleImageUpload}/>
                        <label htmlFor="file"
                               style={{
                                   padding: '10px',
                                   margin: "0 0 1em 0",
                                   backgroundColor: '#22242A',
                                   color: 'white',
                                   cursor: 'pointer',
                                   maxWidth: "85%"
                               }}>
                            {fileName}
                        </label>
                        {isDataLoading ? (
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <Loader/>
                                </div>
                            ) :
                            <button style={{width: "83%", margin: "0 0 1em 0"}}
                                    onClick={() => uploadImage(formValid(nomProduit, categoryProduit, descriptionProduit, sousCategoryProduit, prixProduit, image), setLienImageProduit, image)}>Ajouter
                                produit
                            </button>
                        }
                    </div>
                </div>
            )}
        </>
    )
}

export default AdminAddProduct;
