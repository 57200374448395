function Retractation() {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "1em",
            textAlign: "center"
        }}>
            <h1>Droit de rétractation</h1>
            <h3>Comment puis-je retourner tout ou partie de ma commande ?
            </h3>
            <p>Vous avez 14 jours pour retourner votre produit. Ensuite, vous avez encore 14 jours pour renvoyer le
                colis. Veuillez noter que nous vous rembourserons le montant total de votre commande dans les 5 jours
                ouvrables suivant la réception du produit. Les frais de retours seront à votre charge.
            </p>
            <h3>Suivez les étapes ci-dessous pour retourner votre produit :
            </h3>
            <h4>Directement depuis votre compte dans le détail de votre commande : </h4>
            <p>1. Appuyer sur le bouton 'Retourner mon colis'</p>
            <p>2. Renseignez la raison de votre retour</p>
            <p>4. Si le retour du colis est accepté, retourner le colis à l'adresse que le vendeur aura indiquer.</p>
            <p>5. Au plus tard 10 jours suivant la réception de votre retour, vous serez rembourser si le contenu du
                colis correspond à la commande passé.</p>
            <h4>Par email à l'adresse mail suivante : anneso.naturelle@gmail.com </h4>
            <p>1. Renseigner la raison du retour</p>
            <p>2. Renseigner votre numéro de commande ( vous le trouverez dans le détail de commande dans votre
                compte)</p>
            <p>3. Renseigner une adresse mail vous appartenant pour que le vendeur puisse vous recontacter
                facilement.</p>

            <h3>Annulation de commandes
            </h3>
            <p>L'annulation d'une commande n'est possible que durant 2h suivant la commande, après quoi l'annulation
                d'une commande n'est plus possible.</p>
            <h3>Livraison de produits incorrects
            </h3>
            <p>Nous nous efforçons de traiter correctement toutes les commandes. Malheureusement, il peut arriver que
                des commandes se passent mal et que nous livrions le mauvais produit. Nous résoudrons bien entendu le
                problème et vous enverrons gratuitement la bonne commande.

                Si vous avez des questions concernant la livraison de produits erronés, n'hésitez pas à nous contacter à
                l'adresse mail anneso.naturelle@gmail.com.</p>
            <h2>Questions fréquentes sur notre droit de retour
            </h2>
            <p>Si vous n'êtes toujours pas sûr(e), voici quelques questions fréquemment posées sur notre politique de
                retour :</p>
            <h3>1. Quelle est la durée du délai de retour ?
            </h3>
            <p>Vous pouvez retourner un produit dans les 14 jours suivant la réception de votre commande.
            </p>
            <h3>2. Combien de temps faudra-t-il pour que je sois remboursé ?
            </h3>
            <p>Un remboursement complet sera effectué sur le mode de paiement initial dès que nous aurons reçu les
                produits, que nous les aurons inspectés et que nous aurons confirmé qu'ils n'ont pas été ouverts. Tous
                les clients doivent nous contacter après avoir renvoyé le colis et nous fournir des informations de
                suivi afin que nous puissions traiter le remboursement.
                Veuillez noter que la plupart des institutions financières ont besoin en moyenne de 3 à 5 jours
                ouvrables pour traiter les remboursements.</p>
            <h3>4. Dois-je payer pour mon retour ?
            </h3>
            <p>Oui, tous les clients sont responsables des frais de retour. Nous vous recommandons d'envoyer votre
                retour avec Colissimo, car nous ne pouvons pas être tenus responsables des retours perdus.
            </p>
            <h3>5. Puis-je retourner mon produit sans vous contacter ?
            </h3>
            <p>Non. Vous devez nous contacter avant de nous renvoyer le produit, car l'adresse de retour peut être
                différente de l'adresse d'origine figurant sur votre commande.
            </p>
            <h3>6. Comment puis-je vous contacter ?
            </h3>
            <p>Vous pouvez nous contacter par e-mail à l'adresse mail anneso.naturelle@gmail.com. L'équipe Anne'so
                Naturelle garantie une réponse dans les 3 à 5 jours ouvrables et nous nous ferons un plaisir de vous
                aider !
            </p>
        </div>
    )
}

export default Retractation;