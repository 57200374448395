import Cookies from "js-cookie";

function GetProductById(id, setProduct) {
    const getProducts = async (req, res) => {
        try {
            const product = await fetch(`${process.env.REACT_APP_API_URL}products/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get("auth_token")}`,
                },
            });
            const data = await product.json();
            console.log(data);
            setProduct(data);
        } catch (error) {
            console.error(error);
        }
    }
    getProducts();
}

export default GetProductById;