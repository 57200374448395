import React, {useState, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import "./Details.scss";
import {Loader} from "../../utils/Loader";
import Select from "../../components/Select/Select.tsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCartPlus,
    faChevronCircleDown,
    faChevronCircleUp, faHandHoldingHeart, faBoxOpen, faHandshake, faComments
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import ToastAddToBasket from "../../components/ToastAddToBasket/ToastAddToBasket";
import GoingBack from "../../components/GoingBack/GoingBack";
import ImageCarousel from "./ImagesCarrousel/ImageCarrousel";
import ProductItem from "../../components/ProductItem/ProductItem";
import mesurePoignet from "../../assets/mesure_poignet_2.jpg"

function DetailsProduct({cart, updateCart}) {
    const navigate = useNavigate();
    const {id} = useParams();

    const [isDataLoading, setDataLoading] = useState(false);
    const [productAdd, setProductAdd] = useState(false);
    const [isAddElement, setAddElement] = useState(false);
    const [produit, setProduit] = useState([]);
    const tableauObjet = Object.values(produit);
    const [selectedBraceletSize, setSelectedBraceletSize] = useState("");
    const [erreurChoixTaille, setErreurChoixTaille] = useState("");
    const [infosRemboursement, setInfosRemboursement] = useState(false);
    const [infosDescription, setInfosDescription] = useState(false);
    const [infosAnnesoNaturelle, setInfosAnnesoNaturelle] = useState(false);
    const [lieuFabrication, setLieuFabrication] = useState(false);
    const [materiaux, setMateriaux] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [otherProducts, setOtherProducts] = useState([]);
    const jwtToken = Cookies.get("auth_token");
    console.log(produit)
    console.log(selectedBraceletSize)

    useEffect(() => {
        //window.location.reload();
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            setDataLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}products/${id}`
                );
                const product = await response.json();
                setProduit(Object.values(product));

                const getOtherProducts = async () => {
                    try {
                        const response = await fetch(
                            `${process.env.REACT_APP_API_URL}products/category/3products/${product[0].secondary_category}/${product[0].id}`
                        );
                        const products = await response.json();
                        setOtherProducts(products);
                        console.log(products);
                    } catch (error) {
                        console.error(error);
                    }
                }
                getOtherProducts();
            } catch (error) {
                console.error(error);
            } finally {
                setDataLoading(false);
            }
        };
        fetchData().then((r) => console.log(r), (e) => console.error(e));
    }, []);

    useEffect(() => {
        console.log(produit)
        if (produit && produit.length > 0) {
            document.title = produit[0].name;
        }
    }, [produit]);

    const handleSizeChange = (event) => {
        setSelectedBraceletSize(event.target.value);
        if (event.target.value !== "") {
            setErreurChoixTaille("");
        }
    };

    function addToCart(cover, name, price, idProduct, idPrixStripe, secondary_category) {
        console.log(secondary_category);

        if (selectedBraceletSize === "" && secondary_category === "Bracelet") {
            setProductAdd(false);
            setDataLoading(false);
            setErreurChoixTaille("Veuillez choisir une taille de bracelet");
            return;
        }

        const panierInfos = {
            cover,
            name,
            price,
            idProduct,
            amount: 1,
            idPrixStripe,
            informationsComplementaires: secondary_category === "Bracelet" ? selectedBraceletSize : null,
        };

        if (jwtToken) {
            const insertInBasket = async () => {
                setDataLoading(true);
                try {
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}panier/insert`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${jwtToken}`
                            },
                            body: JSON.stringify({panierInfos}),
                        },
                    );

                    if (response.ok) {
                        const panier = await response.json();
                        updateCart(panier.contenuPanier);
                        const newAmount = panier.newAmount;
                        localStorage.setItem("nbElement", JSON.stringify(newAmount));
                        const nbArticles = JSON.parse(localStorage.getItem("nbArticles"));
                        localStorage.setItem("nbArticles", JSON.stringify(nbArticles + 1));
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setProductAdd(true);
                    setDataLoading(false);
                }
            };
            insertInBasket().then(r => console.log(r));
        } else {
            const currentProductSaved = cart.find((product) =>
                product.idProduct === idProduct &&
                product.informationsComplementaires === selectedBraceletSize
            );

            if (currentProductSaved) {
                const updatedCart = cart.map((product) => {
                    if (product.idProduct === idProduct &&
                        product.informationsComplementaires === selectedBraceletSize) {
                        return {
                            ...product,
                            amount: product.amount + 1
                        };
                    }
                    return product;
                });
                updateCart(updatedCart);
            } else {
                const newProduct = {
                    cover,
                    name,
                    price,
                    idProduct,
                    amount: 1,
                    informationsComplementaires: secondary_category === "Bracelet" ? selectedBraceletSize : null,
                    idPrixStripe
                };
                updateCart([...cart, newProduct]);
            }

            const nbArticles = JSON.parse(localStorage.getItem("nbArticles")) || 0;
            localStorage.setItem("nbArticles", JSON.stringify(nbArticles + 1));
            setAddElement(true);
            setProductAdd(true);
        }
    }

    const handleClickOtherProduct = () => {
        window.location.reload();
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart]);

    return (
        <div className="bodyElementDetails">
            {productAdd === true ?
                <ToastAddToBasket cart={cart} updateCart={updateCart} setProductAdd={setProductAdd}
                                  productAdd={productAdd}/> : null}
            {isDataLoading ? (
                <div className="loader-div">
                    <Loader/>
                </div>
            ) : (
                <>
                    <div className={"div-going-back"}>
                        <GoingBack/>
                    </div>
                    <div className="div-container-left-plus-right">
                        <div className="container-left-details">
                            {produit.map((produit) => (
                                <img
                                    key={produit.id}
                                    src={selectedImage === "" ? produit.covers[0] : selectedImage}
                                    width={250}
                                    height={250}
                                    alt="Image produit collections"
                                    id="image_produit"
                                />
                            ))}
                        </div>
                        <div>
                            {produit.map((produit) => (
                                <div key={produit.id}>
                                    <ImageCarousel images={produit.covers} setSelectedImage={setSelectedImage}/>
                                </div>
                            ))
                            }
                        </div>
                        <div className="details-list">
                            {produit.map((produit) => (
                                <div key={produit.id} style={{width: "90%"}}>
                                    <h1>{produit.name}</h1>
                                    <h3>{produit.price} €</h3>
                                    {produit.secondary_category === "Bracelet" || produit.secondary_category === "Boucle Oreille" || produit.secondary_category === "Accessoire" ? (
                                        <>
                                            <div>
                                                <p><b>Pierres présentes
                                                    sur {produit.secondary_category === "Boucle Oreille" ? "les boucles d'oreilles" : produit.secondary_category === "Accessoire" ? "l'accessoire" : "le bracelet"} :</b>
                                                </p>
                                                <ul>
                                                    {tableauObjet.map((produit) => (
                                                        produit.pierres.map((pierre) => (
                                                            <li>{pierre}</li>
                                                        ))
                                                    ))}
                                                </ul>
                                            </div>
                                            {produit.secondary_category === "Bracelet" ? (

                                                <div id={"div-select_size_product"}>
                                                    <select className={"select-taille-bracelet"}
                                                            value={selectedBraceletSize}
                                                            onChange={handleSizeChange}>
                                                        <option value="">Choisir taille de bracelet
                                                        </option>
                                                        <option value="15 cm">15cm</option>
                                                        <option value="16 cm">16cm</option>
                                                        <option value="17 cm">17cm</option>
                                                        <option value="18 cm">18cm</option>
                                                        <option value="19 cm">19cm</option>
                                                        <option value="20 cm">20cm</option>
                                                        <option value="21 cm">21cm</option>
                                                        <option value="22 cm">22cm</option>
                                                        <option value="23 cm">23cm</option>
                                                        <option value="24 cm">24cm</option>
                                                    </select>
                                                </div>
                                            ) : null}
                                            {erreurChoixTaille ? (
                                                <p style={{
                                                    color: "red",
                                                    margin: "0 0 1em 0",
                                                    padding: "0", fontSize: "0.92em"
                                                }}>{erreurChoixTaille}</p>
                                            ) : null}
                                        </>
                                    ) : null}
                                    <button
                                        id={"button_add_to_cart"}
                                        role="button"
                                        onClick={() =>
                                            addToCart(produit.covers[0], produit.name, produit.price, produit.id, produit.idPrixStripe, produit.secondary_category)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faCartPlus} style={{margin: "0 1em 0 0"}}
                                                         size={"1x"}></FontAwesomeIcon>
                                        Ajouter au panier
                                    </button>
                                    <div style={{width: "100%", cursor: "pointer"}}>
                                        <hr/>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                             onClick={() => infosDescription === false ? setInfosDescription(true) : setInfosDescription(false)}>
                                            <h5>DESCRIPTION DU PRODUIT</h5>
                                            {infosDescription ?
                                                <div><FontAwesomeIcon
                                                    icon={faChevronCircleDown}
                                                    size={"1x"}></FontAwesomeIcon></div> :
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faChevronCircleUp} size={"1x"}></FontAwesomeIcon>
                                                </div>}
                                        </div>
                                        {infosDescription && (
                                            <p style={{maxWidth: "35em"}}>{produit.description}</p>
                                        )
                                        }
                                        <hr/>
                                    </div>
                                    <div style={{
                                        width: "100%",
                                        cursor: "pointer"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                             onClick={() => infosRemboursement === false ? setInfosRemboursement(true) : setInfosRemboursement(false)}>
                                            <h5>SATISFAIT OU REMBOURSE</h5>
                                            {infosRemboursement ?
                                                <div><FontAwesomeIcon
                                                    icon={faChevronCircleDown}
                                                    size={"1x"}></FontAwesomeIcon></div> :
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faChevronCircleUp} size={"1x"}></FontAwesomeIcon>
                                                </div>}
                                        </div>
                                        {infosRemboursement && (
                                            <p style={{maxWidth: "35em"}}>Nous proposons une garantie de 14 jours sans
                                                risque. Si le produit ne vous
                                                convient pas pleinement, contactez-nous dans les 14 jours après sa
                                                réception. Nous nous efforcerons de vous satisfaire. Pour toute demande,
                                                utilisez notre page "Contact".</p>
                                        )
                                        }
                                        <hr/>
                                    </div>
                                </div>
                            ))}
                            {produit[0] && produit[0].secondary_category === "Bracelet" && (
                                <div>
                                    <h4 style={{textAlign: "center"}}>Guide taille poignet</h4>
                                    <img style={{borderRadius: "5px", objectFit: "cover", maxWidth: "100%"}}
                                         src={mesurePoignet} alt={"guide taille poignet"}/>
                                </div>
                            )}
                        </div>
                    </div>
                    {otherProducts.length > 0 && (
                        <h1 style={{textAlign: "center"}}>Vous pourriez aimer ces produits</h1>
                    )}
                    <div className={"example-of-others-products"}>
                        {otherProducts.map(({
                                                id,
                                                covers,
                                                name,
                                                price,
                                                category,
                                                secondary_category,
                                                description,
                                                pierres
                                            }) => (
                            <div key={id} className="div-product" onClick={handleClickOtherProduct}>
                                <ProductItem
                                    id={id}
                                    covers={covers}
                                    name={name}
                                    price={price}
                                    description={description}
                                    category={category}
                                    secondary_category={secondary_category}
                                    pierres={pierres}
                                />
                            </div>
                        ))}
                    </div>

                    <h2 style={{textAlign: "center"}}>Plus de détails sur notre marque et nos produits</h2>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "100%"}}
                         className={"main-div-infos-marque"}>
                        <div className={"secondary-div-infos-sur-marque"}>
                            <div className={"third-div-infos-sur-marque"}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                                     onClick={() => infosAnnesoNaturelle === false ? setInfosAnnesoNaturelle(true) : setInfosAnnesoNaturelle(false)}>
                                    <h4 style={{fontWeight: "bold", color: "#ffffff"}}>Qu'est ce que Anne'so Naturelle
                                        ?</h4>
                                    {infosAnnesoNaturelle ?
                                        <div><FontAwesomeIcon
                                            icon={faChevronCircleDown}
                                            size={"1x"}></FontAwesomeIcon></div> :
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faChevronCircleUp} size={"1x"}></FontAwesomeIcon>
                                        </div>}
                                </div>
                                {infosAnnesoNaturelle && (
                                    <p style={{maxWidth: "35em"}}>Anne'so Naturelle est une boutique où chaque bijou,
                                        conçu avec soin, rayonne d'authenticité. Découvrez nos bracelets faits main,
                                        empreints d'énergie naturelle. Chaque création reflète la beauté simple et la
                                        tranquillité. Plongez dans notre univers où la créativité rencontre le
                                        bien-être. </p>
                                )
                                }
                            </div>
                            <div className={"third-div-infos-sur-marque"}>
                                <hr style={{border: "0", borderBottom: "1px solid #fff"}}/>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                                     onClick={() => lieuFabrication === false ? setLieuFabrication(true) : setLieuFabrication(false)}>
                                    <h4>Où sont fabriqués vos produits ?</h4>
                                    {lieuFabrication ?
                                        <div><FontAwesomeIcon
                                            icon={faChevronCircleDown}
                                            size={"1x"}></FontAwesomeIcon></div> :
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faChevronCircleUp} size={"1x"}></FontAwesomeIcon>
                                        </div>}
                                </div>
                                {lieuFabrication && (
                                    <p style={{maxWidth: "35em"}}>Tous nos produits nécésitant une création à la main,
                                        sont fabriqués dans notre atelier.</p>
                                )
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"div-banderole"}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <FontAwesomeIcon icon={faComments} size={"2x"}></FontAwesomeIcon>
                            <h4 style={{textAlign: "center"}}>À VOTRE ÉCOUTE</h4>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <FontAwesomeIcon icon={faHandHoldingHeart} size={"2x"}></FontAwesomeIcon>
                            <h4 style={{maxWidth: "12em", textAlign: "center"}}>FAIT À LA MAIN AVEC AMOUR</h4>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <FontAwesomeIcon icon={faBoxOpen} size={"2x"}></FontAwesomeIcon>
                            <h4 style={{maxWidth: "12em", textAlign: "center"}}>LIVRAISON GRATUITE À PARTIR DE 50€
                                D'ACHAT</h4>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <FontAwesomeIcon icon={faHandshake} size={"2x"}></FontAwesomeIcon>
                            <h4 style={{textAlign: "center"}}>SOUTENEZ LES ARTISANS</h4>
                        </div>
                    </div>
                </>
            )}
        </div>

    );
}

export default DetailsProduct;
