import React, {useEffect, useState} from "react";
import {Loader} from "../../utils/Loader";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import GoingBack from "../../components/GoingBack/GoingBack";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Admin.scss";
import verifyRoleInJWTtoken from "../../services/VerifyRoleInJWTtoken";
import IsAdmin from "../../components/Admin/IsAdmin";

function AdminOrders() {
    const [commandes, setCommandes] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [idClientClique, setIdClientClique] = useState(false);
    const [user, setUser] = useState([{}]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(null);

    useEffect(() => {
        if (isAdmin === null) {
            return;
        }
        !isAdmin && navigate("/");
    }, [isAdmin]);

    useEffect(() => {
        setIsLoading(false);
        const fetchAllOrders = async () => {
            setDataLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}commandes/getAllOrders`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("auth_token")}`,
                    },
                });
                const commandes = await response.json();
                setCommandes(Object.values(commandes));
            } catch (error) {
                console.log(error);
            } finally {
                setDataLoading(false);
            }
        }
        fetchAllOrders();
    }, []);

    function fetchClientInformation(idClient) {
        try {
            const fetchClient = async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}users/get-user-by-id/${idClient}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                const client = await response.json();
                setUser(client);
                Swal.fire({
                    title: "Informations du client",
                    html: `<p><strong>Nom :</strong> ${client.nom}</p>
                               <p><strong>Prénom :</strong> ${client.prenom}</p>
                               <p><strong>Email :</strong> ${client.adresseEmail}</p>
                               <p><strong>Téléphone :</strong> ${client.numeroTel}</p>`,
                    icon: "info",
                });
            }
            fetchClient();
        } catch (error) {
            console.log(error);
        } finally {
            setIdClientClique(false);
        }
    }

    const handleClickDetails = (idCommande, contenuCommande) => {
        console.log("-------------------------------------------------")
        navigate("/profil/commandes/" + idCommande, {
            state: {
                contenuCommande: contenuCommande,
            },
        });
    };

    const myComposant = p => {
        return (
            <>
                <p style={{color: "blue", cursor: "pointer", margin: "0 0 1em 0"}}
                   onClick={() => handleClickDetails(p.data.idCommande, p.data.contenuCommande)}>Voir détails
                </p>
            </>
        );
    }

    function inverserFormatDate(date) {
        const [annee, mois, jour] = date.split('-');
        return `${jour}-${mois}-${annee}`;
    }

    return (
        <>
            <IsAdmin setAdmin={setIsAdmin}/>
            <GoingBack/>
            {!isAdmin ? null : (
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <h1 style={{margin: "2em 0 2em 2em"}}>Liste de toutes les commandes</h1>

                    <div className="ag-theme-alpine" style={{
                        height: '100%',
                        width: '100%',
                    }}>
                        <AgGridReact
                            rowData={commandes}
                            pagination={true}
                            paginationPageSize={10}
                            domLayout='autoHeight'
                            enableRangeSelection={true}
                            rowSelection="multiple"
                            enableCharts={true}
                            defaultColDef={{
                                flex: 1,
                                minWidth: 100,
                                resizable: true,
                            }}
                            columnDefs={[
                                {
                                    headerName: "Identifiant",
                                    field: "idCommande",
                                    sortable: true,
                                    filter: true,
                                },
                                {
                                    headerName: "Date",
                                    field: "date",
                                    sortable: true,
                                    filter: true,
                                    valueFormatter: (params) => {
                                        return inverserFormatDate(params.value.substring(0, 10));
                                    }
                                },
                                {
                                    headerName: "Prix total",
                                    field: "prixTotal",
                                    valueFormatter: (params) => {
                                        return params.value + " €";
                                    },
                                },
                                {
                                    headerName: "Status",
                                    field: "status",
                                    sortable: true,
                                    filter: true,
                                    cellClassRules: {
                                        'status-annule': p => p.value === "Annulé",
                                        'status-livre': p => p.value === "Livré",
                                        'status-livraison': p => p.value === "En cours de livraison",
                                    },
                                },
                                {
                                    headerName: "Détails",
                                    cellRenderer: myComposant,
                                }
                            ]}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default AdminOrders;