import React, {useEffect, useState} from "react";
import "./CardCommande.scss";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCheck} from "@fortawesome/free-solid-svg-icons"; // ES6

function CardCommande({idCommande, date, prixTotal, contenuCommande, status}) {
    const [btnDetails, setBtnDetails] = useState(false);
    const dateOnly = date.substring(0, 10);

    function inverserFormatDate(date) {
        const [annee, mois, jour] = date.split('-');
        return `${jour}-${mois}-${annee}`;
    }

    const navigate = useNavigate();
    console.log(idCommande)

    const tableauObjet = Object.values(contenuCommande);
    console.log(tableauObjet);

    const coverImages = tableauObjet.map((commande) => (
        <img src={commande.cover} height={100} width={100} alt={'image produit'}></img>
    ));

    const handleClickDetails = () => {
        navigate("/profil/commandes/" + idCommande, {
            state: {
                contenuCommande: tableauObjet,
            },
        });
    };

    return (
        <div className="div-commande">
            <div className="container-left">{coverImages[0]}</div>
            <div>
                <h4>Commande N°{idCommande}</h4>
                {status === "Annulé" ? (
                    <div className={"div-status-commande"} style={{
                        backgroundColor: "#fee2e2",
                        color: "#be2d2d",
                        borderRadius: "5px",
                        maxWidth: "6em",
                        padding: "0.4em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <FontAwesomeIcon icon={faCancel}/>
                        <span style={{margin: "0 0 0 0.5em"}}>Annulée</span>
                    </div>
                ) : status === "En cours de livraison" ? (
                    <div className={"div-status-commande"}>
                        <p style={{backgroundColor: "#bfdbfe", color: "#1d4ed8", padding: "0.5em"}}>En cours de
                            livraison</p>
                    </div>
                ) : status === "Livré" ? (
                    <div className={"div-status-commande"} style={{
                        backgroundColor: "#dcfce7",
                        color: "#052e16",
                        borderRadius: "5px",
                        maxWidth: "6em",
                        padding: "0.25em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <FontAwesomeIcon icon={faCheck}/>
                        <p style={{margin: "0 0 0 0.4em"}}>Livrée</p>
                    </div>
                ) : status === "En cours de préparation" ? (
                    <div className={"div-status-commande"}>
                        <p>En cours de préparation</p>
                    </div>
                ) : status === "Remboursée" ? (
                    <div className={"div-status-commande"} style={{
                        backgroundColor: "#dcfce7",
                        color: "#052e16",
                        borderRadius: "5px",
                        maxWidth: "8em",
                        padding: "0.25em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <FontAwesomeIcon icon={faCheck}/>
                        <p style={{margin: "0 0 0 0.4em"}}>Remboursée</p>
                    </div>
                ) : status === "Demande de retour acceptée" ? (
                    <div className={"div-status-commande"} style={{
                        backgroundColor: "#dcfce7",
                        color: "#052e16",
                        borderRadius: "5px",
                        maxWidth: "10em",
                        padding: "0.25em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <FontAwesomeIcon icon={faCheck}/>
                        <p style={{margin: "0 0 0 0.4em"}}>Retour acceptée</p>
                    </div>
                ) : status === "Demande de retour refusée" ? (
                    <div className={"div-status-commande"} style={{
                        backgroundColor: "#fee2e2",
                        color: "#be2d2d",
                        borderRadius: "5px",
                        maxWidth: "9em",
                        padding: "0.4em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <FontAwesomeIcon icon={faCancel}/>
                        <span style={{margin: "0 0 0 0.5em"}}>Retour refusée</span>
                    </div>
                ) : status === "Non remboursée" ? (
                    <div className={"div-status-commande"} style={{
                        backgroundColor: "#fee2e2",
                        color: "#be2d2d",
                        borderRadius: "5px",
                        maxWidth: "9em",
                        padding: "0.4em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <FontAwesomeIcon icon={faCancel}/>
                        <span style={{margin: "0 0 0 0.5em"}}>Non remboursée</span>
                    </div>
                ) : (
                    <div className={"div-status-commande"}>
                        <p>Inconnu</p>
                    </div>
                )
                }
                <h4>{prixTotal.toFixed(2)} €</h4>
            </div>
            <div className="container-right">
                <h4 style={{width: "6em"}}>{inverserFormatDate(dateOnly)}</h4>
                <button onClick={() => handleClickDetails()}>Voir détails</button>
            </div>
        </div>
    );
}

CardCommande.propTypes = {
    idCommande: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    prixTotal: PropTypes.number.isRequired,
    contenuCommande: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
};

export default CardCommande;
