import GoingBack from "../../components/GoingBack/GoingBack";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import IsAdmin from "../../components/Admin/IsAdmin";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Swal from "sweetalert2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faXmark} from "@fortawesome/free-solid-svg-icons";
import DeleteProduct from "../../services/DeleteProduct";

function AdminAllProducts() {
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(null);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (isAdmin === null) {
            return;
        }
        !isAdmin && navigate("/");
    }, [isAdmin]);

    useEffect(() => {
        const fetchAllProducts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}products`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                const products = await response.json();
                setProducts(products);
            } catch (error) {
                console.log(error);
            }
        };
        fetchAllProducts();
    }, []);

    const handleClickDetails = (idProduit) => {
        console.log("-------------------------------------------------")
        navigate("/admin/produit/" + idProduit);
    };

    const handleClickDelete = (idProduct) => {
        DeleteProduct(idProduct, setProducts)
    }

    const EditOrDeleteProduct = p => {
        return (
            <>
                <FontAwesomeIcon icon={faPen} style={{cursor: "pointer", fontSize: "1.2em"}}
                                 onClick={() => handleClickDetails(p.data.id)}/>{' '}
                <FontAwesomeIcon icon={faXmark} style={{cursor: "pointer", margin: "0 0 0 2em", fontSize: "1.5em"}}
                                 onClick={() => handleClickDelete(p.data.id)}/>
            </>
        );
    }


    return (
        <>
            <IsAdmin setAdmin={setIsAdmin}/>
            <GoingBack/>
            {!isAdmin ? null : (
                <div className="ag-theme-alpine" style={{
                    height: '100%',
                    width: '100%',
                    maxWidth: '1920px'
                }}>
                    <AgGridReact
                        rowData={products}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                        enableRangeSelection={true}
                        rowSelection="multiple"
                        enableCharts={true}
                        defaultColDef={{
                            flex: 1,
                            minWidth: 100,
                            resizable: true,
                        }}
                        columnDefs={[
                            {
                                headerName: "ID",
                                field: "id",
                                sortable: true,
                                filter: true,
                            },
                            {
                                headerName: "Nom",
                                field: "name",
                                sortable: true,
                                filter: true,
                            },
                            {
                                headerName: "Prix",
                                field: "price",
                                valueFormatter: (params) => {
                                    return params.value + " €";
                                },
                                sortable: true,
                                filter: true,
                            },
                            {
                                headerName: "Catégorie",
                                field: "category",
                                sortable: true,
                                filter: true,
                            },
                            {
                                headerName: "Sous catégorie",
                                field: "secondary_category",
                                sortable: true,
                                filter: true,
                            },
                            {
                                headerName: "En ligne",
                                field: "onSale",
                                sortable: true,
                                filter: true,
                                cellRenderer: (params) => {
                                    return params.value ? <p style={{color: "green", margin: 0, padding: 0}}>Oui</p> :
                                        <p style={{color: "red", margin: 0, padding: 0}}>Non</p>;
                                },
                            },
                            {
                                headerName: "Modifier / Supprimer ",
                                cellRenderer: EditOrDeleteProduct,
                            }
                        ]}
                    />
                </div>
            )
            }
        </>
    );
}

export default AdminAllProducts;
