import Swal from "sweetalert2";
import Cookies from "js-cookie";

function DeleteProduct(idProduct, setProducts) {
    const handleClickDelete = () => {
        Swal.fire({
            title: 'Êtes-vous sûr de vouloir supprimer ce produit ?',
            showDenyButton: true,
            confirmButtonText: `Oui`,
            denyButtonText: `Non`,
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    const deleteApplication = async () => {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}products/delete/${idProduct}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${Cookies.get('auth_token')}`,
                            },
                        });
                        if (response.ok) {
                            const data = await response.json();
                            Swal.fire({
                                title: 'Produit supprimée avec succès',
                                icon: 'success',
                                showConfirmButton: false,
                                toast: true,
                                timer: 3000,
                                timerProgressBar: true,
                                position: 'top-end',
                            }).then(() => {
                                setProducts(data);
                            });
                        } else {
                            console.error('User error:', response);
                        }
                    }
                    deleteApplication();
                } catch (error) {
                    console.error(error);
                }
            }
        });
    }
    handleClickDelete();
}

export default DeleteProduct;
