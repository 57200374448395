import React, {useEffect, useState} from "react";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import "./AdminCodePromo.css";
import {Link, useNavigate} from "react-router-dom";
import GoingBack from "../../components/GoingBack/GoingBack";
import Cookies from "js-cookie";
import IsAdmin from "../../components/Admin/IsAdmin";

function AdminCodePromo() {
    const [codePromo, setCodePromo] = useState("");
    const [remise, setRemise] = useState();
    const [remiseEnVirgule, setRemiseEnVirgule] = useState();
    const [dateExpiration, setDateExpiration] = useState("");
    const [codePromos, setCodePromos] = useState([]);
    const [idCodePromoStripe, setIdCodePromoStripe] = useState("");
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(null);


    const addStripeCode = async () => {
        try {
            const stripeObject = {
                codePromo: codePromo,
                reduction: remise,
                dateValidite: dateExpiration,
            }
            console.log(codePromo)
            const addCodePromo = async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}codePromo/stripe`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("auth_token")}`,
                    },
                    body: JSON.stringify(stripeObject),
                });
                const newCodePromo = await response.json();
                console.log(newCodePromo.id);
                console.log(newCodePromo);
                setIdCodePromoStripe(newCodePromo);
            }
            addCodePromo();
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (isAdmin === null) {
            return;
        }
        !isAdmin && navigate("/");
    }, [isAdmin]);

    useEffect(() => {
        console.log(idCodePromoStripe === "");
        if (idCodePromoStripe === "") {
            return;
        }
        try {
            console.log(idCodePromoStripe)
            const codePromoObject = {
                codePromo: codePromo,
                reduction: remiseEnVirgule,
                reductionValeurEntier: remise,
                dateValidite: dateExpiration,
                idCodePromoStripe: idCodePromoStripe,
            }
            console.log(codePromo)
            const addCodePromo = async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}codePromo/add`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("auth_token")}`,
                    },
                    body: JSON.stringify(codePromoObject),
                });
                const newCodePromo = await response.json();
                setCodePromos(newCodePromo)
                console.log(newCodePromo);
                Swal.fire({
                    title: "Code promo ajouté",
                    icon: "success",
                })
            }
            addCodePromo();
        } catch (error) {
            console.log(error)
        }
    }, [idCodePromoStripe]);


    useEffect(() => {
        try {
            const fetchCodePromo = async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}codePromo`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("auth_token")}`,
                    },
                });
                const codePromos = await response.json();
                console.log(codePromos);
                setCodePromos(Object.values(codePromos));
                console.log(codePromos)
            }
            fetchCodePromo();
        } catch (error) {
            console.log(error)
        }
    }, []);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
        const day = String(date.getDate()).padStart(2, '0');

        return `${day}-${month}-${year}`;
    }

    const removePromoCode = async (id) => {
        Swal.fire({
            title: "Êtes-vous sûr de vouloir supprimer ce code promo ?",
            showDenyButton: true,
            confirmButtonText: `Oui`,
            denyButtonText: `Non`,
        }).then((result) => {
            if (result.isConfirmed) {
                const removeCodePromoAfterConfirmation = async () => {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}codePromo/remove/${id}`, {
                            method: "DELETE",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${Cookies.get("auth_token")}`,
                            },
                        });
                        const newCodePromos = await response.json();
                        setCodePromos(newCodePromos);
                        Swal.fire({
                            title: "Code promo supprimé",
                            icon: "success",
                        })
                    } catch (error) {
                        console.log(error);
                    }
                }
                removeCodePromoAfterConfirmation();
            }
        });
    }

    return (
        <div className={"div-principale-admin-code-promo"}>
            <IsAdmin setAdmin={setIsAdmin}/>
            <GoingBack/>
            <div className={"secondary-div"}>
                <div>
                    <div className={"secondary-div-sub"}>
                        <h4 style={{padding: "0 1em 0 0", width: "20%"}}>Code promo</h4>
                        <h4 style={{padding: "0 1em 0 0", width: "20%"}}>Réduction</h4>
                        <h4 style={{padding: "0 4em 0 0", width: "20%"}}>Date d'expiration</h4>
                        <h4 style={{padding: "0 1em 0 0", width: "20%"}}>Validité</h4>
                    </div>
                    {codePromos.map((codePromo, index) => {
                        return (
                            <>
                                <div key={index} className={"secondary-div-sub"}>
                                    <p style={{width: "20%", padding: "0 1em 0 0"}}>{codePromo.codePromo}</p>
                                    <p style={{
                                        width: "20%",
                                        padding: "0 1em 0 0"
                                    }}>{codePromo.reductionValeurEntier} %</p>
                                    <p style={{
                                        width: "20%",
                                        padding: "0 1em 0 0"
                                    }}>{formatDate(codePromo.dateValidite)}</p>
                                    <p style={{
                                        width: "20%",
                                        padding: "0 1em 0 0"
                                    }}>
                                        {new Date(codePromo.dateValidite) < new Date() ?
                                            <p style={{
                                                backgroundColor: "#FCE8E8",
                                                color: "#FF052D",
                                                padding: "0.2em",
                                                borderRadius: "5px", textAlign: "center", maxWidth: "80%"
                                            }}>Invalide</p> :
                                            <p style={{
                                                backgroundColor: "#DCF5D3",
                                                color: "#287834",
                                                padding: "0.2em",
                                                borderRadius: "5px",
                                                textAlign: "center",
                                                maxWidth: "80%"
                                            }}>Valide</p>}
                                    </p>
                                    <FontAwesomeIcon color={"red"} icon={faXmark} style={{cursor: "pointer"}}
                                                     onClick={() => removePromoCode(codePromo._id)}/>
                                </div>
                                <hr/>
                            </>
                        );
                    })}
                </div>
            </div>
            <div className={"third-div"}>
                <h2>Cette espace te permet d'ajouter un code promo en définisant la remise en pourcentage et en nombre à
                    virgule, la date de validité et le code associé.</h2>
                <h4>Exemple : CODEPROMO1234, 0.2, 20, 2024-04-01 (AAAA-MM-JJ)</h4>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <label>Code promo</label>
                    <input style={{width: "80%", padding: "0.8em", margin: "0 0 0.9em 0"}} type="text"
                           onChange={(e) => setCodePromo(e.target.value)}
                    />
                    <label>Remise en % (ex: 20)</label>
                    <input style={{width: "80%", padding: "0.8em", margin: "0 0 0.9em 0"}} type="text"
                           onChange={(e) => setRemise(e.target.value)}/>
                    <label>Remise virgule (ex: 0.2)</label>
                    <input style={{width: "80%", padding: "0.8em", margin: "0 0 0.9em 0"}} type="text"
                           onChange={(e) => setRemiseEnVirgule(e.target.value)}/>
                    <label>Date d'expiration du code</label>
                    <input style={{width: "80%", padding: "0.8em", margin: "0 0 0.9em 0"}} type="text"
                           onChange={(e) => setDateExpiration(e.target.value)}/>
                    <button style={{width: "83%"}} onClick={() => addStripeCode()}>Ajouter</button>
                </div>
            </div>

        </div>
    );
}

export default AdminCodePromo;