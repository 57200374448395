import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import {useNavigate, useLocation} from "react-router-dom";
import Swal from "sweetalert2";
import {Loader} from "../../utils/Loader";

function CreateOrder() {
    const [products, setProducts] = useState([]);
    const [pointRelais, setPointRelais] = useState(Cookies.get("MondialRelayPointDetails") ? JSON.parse(Cookies.get("MondialRelayPointDetails")) : {});
    const [totalCommande, setTotalCommande] = useState(null);
    const [adresse, setAdresse] = useState([]);
    const [isDataLoading, setDataLoading] = useState(false);
    const [orderAdded, setOrderAdded] = useState(false);
    const [idStripeCorrecte, setIdStripeCorrecte] = useState(false);
    const [panierVide, setPanierVide] = useState(false)
    const [tokenStripeRemoved, setTokenStripeRemoved] = useState(false);
    const [user, setUser] = useState([]);
    const [emailSent, setEmailSent] = useState(false);
    const [pointRelaisId, setPointRelaisId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');
    const [payment_intent, setPaymentIntent] = useState("");
    const [relayFetch, setRelayFetch] = useState(false);

    console.log(sessionId)
    const clear = () => {
        sessionStorage.clear();
        localStorage.clear();
        setTimeout(() => {
            navigate("/");
            window.location.reload();
        }, 0);
    }

    /**
     * Vérification de l'identifiant de la session Stripe
     */
    useEffect(() => {
        try {
            const fetchStripeCheckoutSessionId = async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}users`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("auth_token")}`,
                    },
                });
                const user = await response.json();
                console.log(user)
                setUser(user)
                if (user.tokenStripe === sessionId) {
                    setIdStripeCorrecte(true);
                } else {
                    navigate("/panier");
                }
            };
            fetchStripeCheckoutSessionId();
        } catch (error) {
            console.log(error)
        }
    }, []);

    /**
     * Si l'identifiant de la session Stripe est correcte, on peut créer la commande
     */
    useEffect(() => {
        if (!idStripeCorrecte) {
            return;
        }
        try {
            const fetchData = async () => {
                if (Cookies.get('adresseId')) {
                    setDataLoading(true);
                    const response = await fetch(`${process.env.REACT_APP_API_URL}adresses/${Cookies.get("adresseId")}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${Cookies.get("auth_token")}`,
                            },
                        });
                    const adresse = await response.json();
                    setAdresse(Object.values(adresse));
                    console.log(adresse)
                }

                const getProducts = await fetch(`${process.env.REACT_APP_API_URL}panier`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${Cookies.get("auth_token")}`,
                        },
                    });
                const products = await getProducts.json();
                setProducts(Object.values(products.contenuPanier));
                setTotalCommande(products.total);
                console.log(products)
            }
            fetchData();
        } catch (error) {
            console.log(error)
        }
    }, [idStripeCorrecte]);

    /**
     * Récupération de l'intent de paiement
     */

    useEffect(() => {
        if (!totalCommande) {
            return;
        }
        try {
            const getIntentPayment = async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}payment/get-intent-payment`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${Cookies.get("auth_token")}`,
                        },
                        body: JSON.stringify({idSessionsStripe: sessionId,}),
                    });
                if (response.ok) {
                    const paymentIntent = await response.json();
                    setPaymentIntent(paymentIntent.paymentIntent);
                } else {
                    Swal.fire({
                        title: "Erreur",
                        text: "Une erreur est survenue lors de la récupération de votre paiement. Veuillez contacter le service client.",
                        icon: "error",
                        confirmButtonText: "Retour à l'accueil",
                        willClose: () => {
                            navigate("/");
                        }
                    });
                }
            }
            getIntentPayment();
        } catch (error) {
            console.log(error)
        }
    }, [totalCommande]);

    /**
     * Récupération du point relais
     */

    useEffect(() => {
        if (!payment_intent) {
            return;
        }
        if (Cookies.get("MondialRelayPointDetails")) {
            const isRelayPointAlreadyExist = async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}mondialRelay/id/${pointRelais.Num}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${Cookies.get("auth_token")}`,
                        },
                    });
                const result = await response.json();
                if (!response.ok) {
                    console.log("Erreur lors de la récupération du point relais");
                    insertNewMondialRelay();
                } else {
                    console.log(result)
                    console.log(result.idPointRelay)
                    setPointRelaisId(result.idPointRelay);
                }

                function insertNewMondialRelay() {
                    const detailsRelais = {
                        idPointRelais: pointRelais.Num,
                        adresse: pointRelais.LgAdr3,
                        enseigne: pointRelais.LgAdr1,
                        codePostal: pointRelais.CP,
                        ville: pointRelais.Ville,
                        pays: pointRelais.Pays,
                        latitude: pointRelais.Latitude,
                        longitude: pointRelais.Longitude,
                        urlImage: pointRelais.URL_Photo,
                    }
                    const insertNewRelay = async () => {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}mondialRelay/addRelay`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${Cookies.get("auth_token")}`,
                                },
                                body: JSON.stringify(detailsRelais)
                            });
                        const result = await response.json();
                        if (response.ok) {
                            setPointRelaisId(result.idPointRelais);
                        } else {
                            console.log("Erreur lors de l'insertion du point relais")
                        }
                    }
                    insertNewRelay();
                }

                console.log(result)
            }
            isRelayPointAlreadyExist();
        } else {
            setPointRelaisId("test");
        }
    }, [payment_intent]);

    /**
     * Création de la commande
     */

    // ne va pas dans le bon
    useEffect(() => {
        if (!pointRelaisId || !adresse) {
            return;
        }
        console.log(adresse)
        console.log(adresse[5])
        const createOrder = () => {
            try {
                const timestamp = new Date().getTime();
                console.log(adresse)
                const commande = {
                    idCommande: timestamp,
                    idClient: Cookies.get("auth_token"),
                    date: new Date(),
                    nbArticles: products.length,
                    adresseLivraison: pointRelaisId === "test" ? Cookies.get("adresseId") : null,
                    adressePointRelais: pointRelaisId === "test" ? null : pointRelaisId,
                    prixTotal: Math.round(totalCommande * 100) / 100,
                    contenuCommande: products,
                    numeroSuivieMondialRelay: "",
                    status: "En cours de préparation",
                    typeLivraison: Cookies.get("adresseId") ? "A domicile" : "En point relais",
                    fraisLivraison: totalCommande >= 50 ? 0 : Cookies.get("adresseId") ? 5.99 : 3.99,
                    codePostalCommande: adresse.length > 0 ? adresse[5] : pointRelais.CP[0],
                    numeroSuivieColissimo: "",
                    idMoyenPaiementStripe: payment_intent,
                }
                const ajoutCommande = async () => {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}commandes/addOrder`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${Cookies.get("auth_token")}`,
                            },
                            body: JSON.stringify(commande)
                        });
                    const result = await response.json();
                    console.log(result)
                    if (response.ok) {
                        setOrderAdded(true);
                    } else {
                        console.error("Erreur lors de l'ajout de la commande")
                    }
                }
                ajoutCommande();

            } catch (error) {
                console.log(error)
            }
        }
        createOrder();
    }, [pointRelaisId]);


    /**
     * Envoi de l'email de confirmation de commande
     */
    useEffect(() => {
        if (orderAdded !== true) {
            return;
        }
        const emailInfos = {
            email: user.adresseEmail,
        }

        const sendConfirmationEmail = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}mail/seller-to-client`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("auth_token")}`,
                    },
                    body: JSON.stringify({
                        products: products,
                        total: totalCommande,
                        adresse: adresse ? adresse : null,
                        pointRelais: pointRelais ? pointRelais : null,
                        emailInfos,
                        fraisLivraison: totalCommande >= 50 ? 0 : Cookies.get("adresseId") ? 5.99 : 3.99
                    }),
                });
            return response;
        }
        /**
         * Faire la même chose mais envoyer à maman
         */
        sendConfirmationEmail().then(() => {
            setEmailSent(true);
        });
    }, [orderAdded]);

    useEffect(() => {
        if (!emailSent) {
            return;
        }
        try {
            const sentEmptyCart = async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}panier/delete-all`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${Cookies.get("auth_token")}`,
                        },
                    });
                console.log(response)
            };
            sentEmptyCart().then(() => {
                setPanierVide(true);
            });
        } catch (error) {
            console.log(error)
        }
    }, [emailSent]);


    useEffect(() => {
        if (!panierVide) {
            return;
        }
        const handleUpdateUserStripeToken = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}payment/update-user-stripe-token`,
                    {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${Cookies.get("auth_token")}`,
                        },
                    }
                );
                return response;
            } catch (error) {
                console.error("Erreur de connexion au serveur:", error);
            } finally {
                setTokenStripeRemoved(true);
                setDataLoading(false);
            }
        }
        handleUpdateUserStripeToken().then((response) => {
            if (response.ok) {
                Swal.fire({
                    title: "Commande validée",
                    text: "Votre commande a bien été enregistrée. Vous allez recevoir un email de confirmation.",
                    icon: "success",
                    confirmButtonText: "Retour à l'accueil",
                    willClose: () => {
                        clear();
                    }
                });
            } else {
                Swal.fire({
                    title: "Erreur",
                    text: "Une erreur est survenue lors de l'envoi de l'email de confirmation. Veuillez contacter le service client.",
                    icon: "error",
                    confirmButtonText: "Retour à l'accueil",
                    willClose: () => {
                        navigate("/");
                    }
                });
            }
        });
    }, [panierVide]);

    return (
        <>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh"}}>
                {isDataLoading ? <Loader/> : null}
            </div>
        </>
    )
}

export default CreateOrder;
