import ButtonDeconnect from "../../components/Button Deconnect/ButtonDeconnect";
import React, {useEffect, useState} from "react";
import Cookies from "js-cookie";
import {Link, useNavigate} from "react-router-dom";
import verifyRoleInJWTtoken from "../../services/VerifyRoleInJWTtoken";
import IsAdmin from "../../components/Admin/IsAdmin";

function Admin() {
    const [seeAllOrders, setSeeAllOrders] = useState(false);
    const [addPromoCode, setAddPromoCode] = useState(false);
    const [addProduct, setAddProduct] = useState(false);
    const [seeAllProducts, setSeeAllProducts] = useState(false);
    const [isAdmin, setIsAdmin] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(isAdmin)
        if (isAdmin === null) {
            return;
        }
        !isAdmin && navigate("/");
    }, [isAdmin]);

    useEffect(() => {
        if (seeAllOrders) {
            navigate("/admin/orders");
        }
        setSeeAllOrders(false);
    }, [seeAllOrders]);

    useEffect(() => {
        if (addPromoCode) {
            navigate("/admin/promo-code");
        }
        setAddPromoCode(false);
    }, [addPromoCode]);

    useEffect(() => {
        if (addProduct) {
            navigate("/admin/add-product");
        }
        setAddProduct(false);
    }, [addProduct]);

    useEffect(() => {
        if (seeAllProducts) {
            navigate("/admin/products");
        }
        setSeeAllProducts(false);
    }, [seeAllProducts]);

    return (
        <>
            <IsAdmin setAdmin={setIsAdmin}/>
            {isAdmin ? (
                <div className={"div-main-admin-home"}>
                    <h1 style={{textAlign: "center"}}>Partie Administrateur</h1>
                    <div className={"div-button-admin-home"}>
                        <button onClick={() => setSeeAllOrders(true)}>Voir toutes les commandes
                        </button>
                        <button onClick={() => setAddPromoCode(true)}>Ajouter un code promo,
                            modifier un existant
                        </button>
                        <button onClick={() => setAddProduct(true)}>Ajouter un produit</button>
                        <button onClick={() => setSeeAllProducts(true)}>Voir tous les produits</button>
                    </div>
                    <div style={{position: "relative"}}>
                        <ButtonDeconnect></ButtonDeconnect>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default Admin;