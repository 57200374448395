import OrderProgress from "../../components/OrderProgress/OrderProgress";
import "./CheckOutPayment.scss";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import GoingBack from "../../components/GoingBack/GoingBack";
import {Loader} from "../../utils/Loader";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import ButtonDeconnect from "../../components/Button Deconnect/ButtonDeconnect";
import AccountActiveOrNot from "../../components/AccountActiveOrNot/AccountActiveOrNot";
import TextAccountNotActiveWithDeconnectButton
    from "../../components/AccountActiveOrNot/TextAccountNotActiveWithDeconnectButton";
import Swal from "sweetalert2";

function CheckoutPayment() {
    const [adresse, setAdresse] = useState([]);
    const [pointRelais, setPointRelais] = useState(Cookies.get("MondialRelayPointDetails") ? JSON.parse(Cookies.get("MondialRelayPointDetails")) : {});
    const [products, setProducts] = useState([])
    const [totalCommande, setTotalCommande] = useState(0);
    const [totalCommandeWithPromo, setTotalCommandeWithPromo] = useState(0);
    const [conditionsAccepter, setConditionsAccepter] = useState(false);
    const [erreurConditionsNonAccepter, setErreurConditionsNonAccepter] = useState("");
    const [isDataLoading, setDataLoading] = useState(false);
    const [paiementOk, setPaiementOk] = useState(false);
    const codePromoActif = localStorage.getItem("codePromoActif");
    const [accountActive, setAccountActive] = useState(null);
    const [codePromo, setCodePromo] = useState(
        codePromoActif && codePromoActif !== "" ? JSON.parse(codePromoActif) : {}
    );
    const navigate = useNavigate();
    console.log(totalCommande)
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Récapitulatif commande";
        const cartItems = JSON.parse(localStorage.getItem("cart"));
        if (!cartItems || cartItems.length === 0) {
            navigate("/panier");
        }
        try {
            const fetchData = async () => {
                if (Cookies.get('adresseId')) {
                    setDataLoading(true);
                    const response = await fetch(`${process.env.REACT_APP_API_URL}adresses/${Cookies.get("adresseId")}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${Cookies.get("auth_token")}`,
                            },
                        });
                    const adresse = await response.json();
                    setAdresse(Object.values(adresse));
                    console.log(adresse)
                }

                const getProducts = await fetch(`${process.env.REACT_APP_API_URL}panier`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${Cookies.get("auth_token")}`,
                        },
                    });
                const products = await getProducts.json();
                setProducts(Object.values(products.contenuPanier));
                setTotalCommande(products.total);
                setTotalCommandeWithPromo(codePromoActif !== null ? products.total - (products.total * codePromo.reductionValeurEntier / 100) : products.total)
                console.log(products)
                setDataLoading(false);
                Cookies.remove("MondialRelayPoint");
            }
            fetchData();
        } catch (error) {
            console.log(error)
        }
    }, []);

    const handleClickPayerCommande = () => {
        if (!conditionsAccepter) {
            setErreurConditionsNonAccepter("Vous devez accepter les conditions générales de ventes pour continuer");
            return;
        }
        const payerCommande = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}payment/create-checkout-session`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("auth_token")}`,
                    },
                    body: JSON.stringify({
                        products: products,
                        codePromo: codePromo.reduction,
                        idCodePromoStripe: codePromo.idCodePromoStripe,
                        fraisLivraison: totalCommande >= 50 ? 0 : Cookies.get("adresseId") ? 599 : 399,
                    })
                });
            const result = await response.json();
            if (response.ok) {
                setPaiementOk(true);
                window.location.href = result.url;
            } else if (response.status === 500) {
                console.log(result);
                Swal.fire({
                    icon: "error",
                    title: "Erreur lors de la création du paiment de votre commande",
                    text: "Veuillez contacter le support pour plus d'informations, ou bien réessayer plus tard. Nous sommes navré pour la gêne occasionnée.",
                });
            }
        }
        payerCommande();
    }

    return (
        <>
            <AccountActiveOrNot setAccountActive={setAccountActive}/>
            {accountActive === false && accountActive !== null ? (
                <TextAccountNotActiveWithDeconnectButton/>
            ) : (
                <div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <a href={"/"}>
                            <img
                                src={"https://res.cloudinary.com/dc1p20eb2/image/upload/v1711445140/Anne%27so%20Naturelle/logo/logo.png"}
                                alt={"logo anneso-naturelle"} height={125} width={200}/>
                        </a>
                    </div>

                    <GoingBack/>
                    <OrderProgress/>
                    {isDataLoading ? (
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Loader/>
                            </div>
                        ) :
                        <div className={"main-div-checkout-payment"}>
                            <div>
                                <h2 style={{margin: "2em 0 0 0"}}>Détails de votre commande</h2>
                                <hr/>
                                <div>
                                    {products && products.map((product) => {
                                        return (
                                            <div className={"div-secondary-details-products"}>
                                                <div>
                                                    <img style={{borderRadius: "7px"}} src={product.cover} height={100}
                                                         width={100}/>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    margin: "0.5em 0 0 0"
                                                }}>
                                                    <h4 style={{margin: "0 0 2em", maxWidth: "8em"}}>{product.name}</h4>
                                                    {product.informationsComplementaires && (
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            backgroundColor: "#031633",
                                                            borderRadius: "7px",
                                                            padding: "0.4em",
                                                            maxWidth: "5em",
                                                            color: "#fff"
                                                        }}>
                                                            <FontAwesomeIcon icon={faCircleInfo}/>
                                                            <p style={{margin: "0 0 0 0.5em"}}>{product.informationsComplementaires}</p>
                                                        </div>
                                                    )}
                                                </div>
                                                <div style={{margin: "0.5em 1em 0 0"}}>
                                                    <h4 style={{margin: "0 0 0.5em 0"}}>{product.price.toFixed(2)} €</h4>
                                                    <h4 style={{margin: "0"}}>x {product.amount}</h4>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {Cookies.get("MondialRelayPointDetails") && (
                                <div>
                                    <h2 style={{margin: "2em 0 0 0"}}>Point relais choisit</h2>
                                    <hr style={{margin: "0 0 2em 0"}}/>
                                    <div style={{display: "flex", flexDirection: "column", margin: "1em 0 0 0"}}>
                                        <p>{pointRelais.LgAdr1}</p>
                                        <p>{pointRelais.LgAdr3}</p>
                                        <p>{pointRelais.CP} {pointRelais.Ville}</p>
                                        <img src={pointRelais.URL_Photo} alt={"photo point relais anneso-naturelle"}/>
                                    </div>
                                </div>
                            )}
                            {Cookies.get("adresseId") &&
                                <div>
                                    <h2 style={{margin: "2em 0 0 0"}}>Détails de votre livraison</h2>
                                    <hr/>
                                    <p>{adresse[2]} {adresse[3]}</p>
                                    <p>{adresse[4]}</p>
                                    <p>{adresse[5]} {adresse[6]}</p>
                                    <p>{adresse[8]}</p>
                                    <p>{adresse[9]}</p>
                                </div>
                            }
                            <div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    margin: "2em 0 0 0"
                                }}>
                                    <h3>Total (TVA incluse)</h3>
                                    <h3>{(totalCommandeWithPromo > 50 ? totalCommandeWithPromo : totalCommandeWithPromo + (Cookies.get("adresseId") ? 5.90 : 3.90)).toFixed(2)} €</h3>
                                </div>
                                <hr/>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                    <p>Sous-total</p>
                                    <p>{totalCommande.toFixed(2)} €</p>
                                </div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                    <p>Frais de livraison</p>
                                    {totalCommande.toFixed(2) >= 50.00 ? <p>Offerts</p> :
                                        <p>{Cookies.get("adresseId") ? 5.90.toFixed(2) : 3.90.toFixed(2)} €</p>}
                                </div>
                                {codePromoActif !== null ?
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        <p>Réduction appliqué</p>
                                        <p style={{color: "#008000"}}>- {(totalCommande * codePromo.reduction).toFixed(2)} €</p>
                                    </div> : null
                                }
                                <div>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setConditionsAccepter(e.target.checked)}
                                        style={{margin: "1em 0 0 0"}}/>
                                    <label style={{margin: "0 0 0 0.4em"}}>J'ai lu et j'accepte les <a
                                        href={"/conditions-generales"}><u>conditions générales de
                                        ventes</u></a></label>
                                </div>
                                {console.log(conditionsAccepter)}
                                {erreurConditionsNonAccepter && !conditionsAccepter && <p style={{
                                    maxWidth: "25em",
                                    color: "red",
                                    margin: "1em 0 0 0",
                                    textAlign: "center"
                                }}>{erreurConditionsNonAccepter}</p>}
                                <button style={{margin: "2em 0 0 0", width: "100%"}}
                                        onClick={() => handleClickPayerCommande()}>Payer ma
                                    commande
                                </button>
                            </div>
                        </div>
                    }
                </div>
            )}
        </>
    )
}

export default CheckoutPayment;
