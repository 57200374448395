import {useLocation} from "react-router-dom";
import NavBarProfil from "../../components/NavBarProfil/NavBarProfil";
import "./Favoris.scss";
import CardFavoris from "../../components/CardFavoris/CardFavoris";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";

function Favoris() {
    const location = useLocation();
    const {favoris} = location.state || {};

    const [tableauObjet, setFavoris] = useState([]);

    useEffect(() => {
        setFavoris(favoris);
        document.title = "Mes favoris";
    }, []);

    return (
        <div className="body-page-favoris">
            <NavBarProfil></NavBarProfil>
            <div className="container-right-favoris">
                <h1 style={{textAlign: "center"}}>Mes Favoris</h1>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    flexWrap: "wrap"
                }}>
                    {favoris.length > 0 ? (
                        tableauObjet.map((favori) => (
                            <CardFavoris
                                key={favori.clientId}
                                coverArticle={favori.coverArticle}
                                prixArticle={favori.prixArticle}
                                nomArticle={favori.nomArticle}
                                idProduct={favori.idProduct}
                                setFavoris={setFavoris}
                                favoris={favoris}
                            ></CardFavoris>
                        ))
                    ) : (
                        <div style={{
                            display: "flex", alignItems: "flex-start", justifyContent: "flex-start", height: "90dvh"
                        }}><h3>Aucun favoris pour le moment</h3>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Favoris;
