import React, {createContext, useContext, useEffect, useState} from 'react';

const FiltreContext = createContext();

export const FiltreProvider = ({children}) => {
    const [filtreOuvert, setFiltreOuvert] = useState(false);
    const [activeCategory, setActiveCategory] = useState(sessionStorage.getItem('categorieFiltre') || "");
    const [triageActive, setActiveTriage] = useState("");
    const [minPriceForThisCategory, setminPriceForThisCategory] = useState(0);
    const [maxPriceForThisCategory, setmaxPriceForThisCategory] = useState(0);
    const [filtreValider, setFiltreValider] = useState(false);
    const [minPriceContext, setMinPriceContext] = useState(0);
    const [maxPriceContext, setMaxPriceContext] = useState(0);
    const [colorsContext, setColorsContext] = useState(JSON.parse(sessionStorage.getItem('couleursChoisies')) || []);
    const [pierresContext, setPierresContext] = useState(JSON.parse(sessionStorage.getItem('pierresChoisies')) || []);
    const toggleFiltre = () => {
        setFiltreOuvert(!filtreOuvert);
    };
    console.log(pierresContext)
    console.log("Colors context " + colorsContext)

    return (
        <FiltreContext.Provider value={{
            filtreOuvert,
            toggleFiltre,
            activeCategory,
            setActiveCategory,
            triageActive,
            setActiveTriage,
            minPriceForThisCategory,
            setminPriceForThisCategory,
            maxPriceForThisCategory,
            setmaxPriceForThisCategory,
            setFiltreOuvert,
            setFiltreValider,
            filtreValider,
            setMinPriceContext,
            minPriceContext,
            maxPriceContext,
            setMaxPriceContext, setColorsContext, colorsContext, setPierresContext, pierresContext
        }}>
            {children}
        </FiltreContext.Provider>
    );
};

export const useFiltre = () => {
    const context = useContext(FiltreContext);
    if (!context) {
        throw new Error('useFiltre must be used within a FiltreProvider');
    }
    return context;
};
