import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import NavBarProfil from "../../components/NavBarProfil/NavBarProfil";
import CardCommande from "../../components/CardCommande/CardCommande";
import AccountActiveOrNot from "../../components/AccountActiveOrNot/AccountActiveOrNot";
import ButtonDeconnect from "../../components/Button Deconnect/ButtonDeconnect";
import TextAccountNotActiveWithDeconnectButton
    from "../../components/AccountActiveOrNot/TextAccountNotActiveWithDeconnectButton";
import "./Commandes.scss";

function Commandes() {
    const location = useLocation();
    const {commandes} = location.state || {};
    const [accountActive, setAccountActive] = useState(null);

    const tableauObjet = Object.values(commandes);

    // Trier les commandes par date la plus récente
    tableauObjet.sort((a, b) => new Date(b.date) - new Date(a.date));

    useEffect(() => {
        document.title = "Mes commandes";
    }, []);

    return (
        <>
            <AccountActiveOrNot setAccountActive={setAccountActive}/>
            {accountActive === false && accountActive !== null ? (
                <TextAccountNotActiveWithDeconnectButton/>
            ) : (
                <div className="div-main-profil">
                    <NavBarProfil></NavBarProfil>
                    <div className={"div-commandes"}>
                        <h1>Mes Commandes</h1>
                        {commandes.length > 0 ? (
                            tableauObjet.map((commande) => (
                                <CardCommande
                                    key={commande.idCommande}
                                    idCommande={commande.idCommande}
                                    date={commande.date}
                                    prixTotal={commande.prixTotal}
                                    contenuCommande={commande.contenuCommande}
                                    status={commande.status}
                                ></CardCommande>
                            ))
                        ) : (
                            <div style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                height: "90dvh"
                            }}>
                                <h3>Aucune commande pour le moment</h3>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default Commandes;
