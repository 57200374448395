import React from "react";
import ButtonDeconnect from "../../components/Button Deconnect/ButtonDeconnect";

function TextAccountNotActiveWithDeconnectButton() {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100dvh"
        }}>
            <p style={{textAlign: "center", fontWeight: "bold"}}>Votre compte n'est pas encore activé</p>
            <p style={{textAlign: "center"}}>Veuillez vérifier vos emails et cliquer sur le lien
                d'activation.</p>
            <ButtonDeconnect/>
        </div>
    );
}

export default TextAccountNotActiveWithDeconnectButton;
