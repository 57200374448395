import "./FiltreEtTrie.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSliders, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useRef, useState} from "react";
import Slider from "rc-slider";
import {useSwipeable} from "react-swipeable";
import {useFiltre} from '../../utils/FiltreContext';

function FiltreEtTrie({productList, setminPrice, setMaxPrice, minPrice, maxPrice}) {
    const {
        filtreOuvert,
        toggleFiltre,
        activeCategory,
        setActiveCategory,
        triageActive,
        setActiveTriage,
        minPriceForThisCategory,
        setMinPriceForThisCategory,
        maxPriceForThisCategory,
        setmaxPriceForThisCategory,
        setFiltreOuvert,
        filtreValider,
        setFiltreValider, setMaxPriceContext, setMinPriceContext, setColorsContext, setPierresContext
    } = useFiltre();
    const [changePriceClique, setChangePriceClique] = useState(false);
    const [min, setMin] = useState(minPriceForThisCategory);
    const [max, setMax] = useState(maxPriceForThisCategory);
    const [range, setRange] = useState([
        sessionStorage.getItem('filtrePrixBaseCategorie') ? JSON.parse(sessionStorage.getItem('filtrePrixBaseCategorie'))[0] : minPriceForThisCategory,
        sessionStorage.getItem('filtrePrixBaseCategorie') ? JSON.parse(sessionStorage.getItem('filtrePrixBaseCategorie'))[1] : maxPriceForThisCategory,
    ]);
    const [pierresBracelets, setPierresBracelets] = useState([]);
    const [pierresChoisies, setPierresChoisies] = useState(JSON.parse(sessionStorage.getItem('pierresChoisies')) || []);
    const [couleursChoisies, setCouleursChoisies] = useState(JSON.parse(sessionStorage.getItem('couleursChoisies')) || []);
    const [colors, setColors] = useState([]);

    const handleClose = () => {
        setFiltreOuvert(false);
        document.body.classList.remove('body-lock-scroll');
    };

    const handlers = useSwipeable({
        onSwipedDown: () => handleClose(),
        preventDefaultTouchmoveEvent: true,
    });

    const handleSliderChange = (minParam, maxParam, range) => {
        setMin(minParam);
        setMax(maxParam);
        setRange(range);
        setMinPriceContext(minParam);
        setMaxPriceContext(maxParam);
        sessionStorage.setItem('filtrePrix', JSON.stringify([minParam, maxParam]));
    };

    useEffect(() => {
        const minPrice = sessionStorage.getItem("filtrePrixBaseCategorie") ? JSON.parse(sessionStorage.getItem("filtrePrixBaseCategorie"))[0] : minPriceForThisCategory;
        const maxPrice = sessionStorage.getItem("filtrePrixBaseCategorie") ? JSON.parse(sessionStorage.getItem("filtrePrixBaseCategorie"))[1] : maxPriceForThisCategory;
        setRange([minPrice, maxPrice]);
    }, [maxPriceForThisCategory]);

    const trackStyle = {
        backgroundColor: "#040037",
        height: 8
    };

    /**
     * * Récupère les pierres de la liste de produit si les produits sont des bracelets
     * @returns {Promise<*>}
     */
    const functionPierresBijoux = async () => {
        if (!productList) return [];
        const pierresBijoux = productList.reduce((acc, product) => {
            if ((product.secondary_category === "Bracelet" || product.secondary_category === "Boucle Oreille" || product.secondary_category === "Accessoire") && (product.onSale) && (!acc.includes(product.pierres))) {
                acc = acc.concat(product.pierres);
            }
            console.log(acc)
            return acc;
        }, []);
        return pierresBijoux.filter(
            (pierre, index) => pierresBijoux.indexOf(pierre) === index
        );
    }

    useEffect(() => {
        const fetchPierres = async () => {
            const bijoux = await functionPierresBijoux();
            setPierresBracelets(bijoux);
        }
        fetchPierres().then(r => console.log(r));
    }, [activeCategory, productList]);

    const handleChoixPierres = async (pierre) => {
        setPierresChoisies(prevPierresChoisies => {
            const estDeselectionnee = prevPierresChoisies.includes(pierre);

            let nouvellesPierresChoisies;

            if (estDeselectionnee) {
                // Si la pierre est déjà choisie, la retire de la liste
                nouvellesPierresChoisies = prevPierresChoisies.filter(p => p !== pierre);
            } else {
                // Ajoute la pierre à la liste
                nouvellesPierresChoisies = [...prevPierresChoisies, pierre];
            }
            setPierresContext(nouvellesPierresChoisies);
            sessionStorage.setItem('pierresChoisies', JSON.stringify(nouvellesPierresChoisies));
            return nouvellesPierresChoisies;
        });
    }


    /**
     * Couleur des pierres de bracelets
     * @returns {Promise<*>}
     */
    const functionColor = async () => {
        if (!productList) return [];
        const colorsProduct = productList.reduce((acc, product) => {
            if (product.onSale) {
                acc = acc.concat(product.color);
            }
            return acc;
        }, []);
        return colorsProduct.filter(
            (color, index) => colorsProduct.indexOf(color) === index
        );
    }

    useEffect(() => {
        const fetchColor = async () => {
            const colors = await functionColor();
            setColors(colors);
        }
        fetchColor().then(r => console.log(r));
    }, [activeCategory, productList]);

    const handleChoixCouleurs = async (couleur) => {
        setCouleursChoisies(prevCouleurChoisie => {
            const estDeselectionnee = prevCouleurChoisie.includes(couleur);

            let nouvellesCouleursChoisies;

            if (estDeselectionnee) {
                // Si la pierre est déjà choisie, la retire de la liste
                nouvellesCouleursChoisies = prevCouleurChoisie.filter(p => p !== couleur);
            } else {
                // Ajoute la pierre à la liste
                nouvellesCouleursChoisies = [...prevCouleurChoisie, couleur];
            }
            // Mise à jour du state
            setColorsContext(nouvellesCouleursChoisies);
            sessionStorage.setItem('couleursChoisies', JSON.stringify(nouvellesCouleursChoisies));
            return nouvellesCouleursChoisies;
        });
    }

    console.log(activeCategory)


    const handleFiltreValider = () => {
        setFiltreValider(true)
        setFiltreOuvert(false)
    }

    const removeFilter = (filter) => {
        if (filter === "pierres") {
            sessionStorage.removeItem('pierresChoisies');
            setPierresChoisies([]);
            setPierresContext([]);
        } else if (filter === "couleurs") {
            sessionStorage.removeItem('couleursChoisies');
            setCouleursChoisies([]);
            setColorsContext([]);
        } else if (filter === "prix") {
            sessionStorage.removeItem('filtrePrix');
            setMinPriceContext(minPrice);
            setMaxPriceContext(maxPrice);
            setMin(minPrice);
            setMax(maxPrice);
            setRange([minPrice, maxPrice]);
        }
    }

    return (
        <>
            <div
                className={`main-div-filtre-et-trie ${filtreOuvert ? "filtre-visible" : ""}`}
                style={{display: filtreOuvert ? "block" : "none"}}
                {...handlers}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between", margin: "0 0 0 1em"
                }}>
                    <h3>Trier et filtrer</h3>
                    <FontAwesomeIcon icon={faXmark} onClick={() => setFiltreOuvert(false)}
                                     style={{margin: "0 1em 0 0", fontSize: "1.5em", cursor: "pointer"}}/>
                </div>
                <div style={{display: "flex", flexDirection: "row"}}>
                    {JSON.parse(sessionStorage.getItem('filtrePrix')) && ((JSON.parse(sessionStorage.getItem("filtrePrix"))[0] !== JSON.parse(sessionStorage.getItem("filtrePrixBaseCategorie"))[0]) || (JSON.parse(sessionStorage.getItem("filtrePrix"))[1] !== JSON.parse(sessionStorage.getItem("filtrePrixBaseCategorie"))[1])) ? (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            backgroundColor: "#ECF3F8",
                            maxWidth: "4em",
                            padding: "0.5em",
                            borderRadius: "10px",
                            margin: "0 0.5em 0 1em"
                        }}>
                            <p style={{
                                padding: "0",
                                borderRadius: "7px",
                                alignItems: "center",
                                margin: "0 1em 0 0"
                            }}>Prix</p>
                            <FontAwesomeIcon icon={faXmark} onClick={() => removeFilter("prix")}/>
                        </div>
                    ) : null}
                    {JSON.parse(sessionStorage.getItem("pierresChoisies")) && JSON.parse(sessionStorage.getItem("pierresChoisies")).length !== 0 ? (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            backgroundColor: "#ECF3F8",
                            maxWidth: "5em",
                            padding: "0.5em",
                            borderRadius: "10px",
                            margin: "0 0.5em 0 0.5em"
                        }}>
                            <p style={{
                                padding: "0",
                                borderRadius: "7px",
                                alignItems: "center",
                                margin: "0 1em 0 0"
                            }}>Pierres</p>
                            <FontAwesomeIcon icon={faXmark} onClick={() => removeFilter("pierres")}/>
                        </div>
                    ) : null}
                    {JSON.parse(sessionStorage.getItem("couleursChoisies")) && JSON.parse(sessionStorage.getItem("couleursChoisies")).length !== 0 ? (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            backgroundColor: "#ECF3F8",
                            maxWidth: "6em",
                            padding: "0.5em",
                            borderRadius: "10px",
                            margin: "0 0.5em 0 0.5em"
                        }}>
                            <p style={{
                                padding: "0",
                                borderRadius: "7px",
                                alignItems: "center",
                                margin: "0 1em 0 0"
                            }}>Couleurs</p>
                            <FontAwesomeIcon icon={faXmark} onClick={() => removeFilter("couleurs")}/>
                        </div>
                    ) : null}
                </div>
                <p></p>
                <div>
                    <div style={{backgroundColor: "#F3F3F5", padding: "0.5em"}}>
                        <h4 style={{margin: 0}}>Trier par</h4>
                    </div>
                    <div className="div-trie">
                        <label>
                            <input
                                type="radio"
                                value="nom"
                                checked={triageActive === "nom"}
                                onChange={(e) => setActiveTriage(e.target.value)}
                            ></input>
                            Nom
                        </label>
                    </div>
                    <div className="div-trie">
                        <label>
                            <input
                                type="radio"
                                value="croissant"
                                checked={triageActive === "croissant"}
                                onChange={(e) => setActiveTriage(e.target.value)}
                            ></input>
                            Prix croissant
                        </label>
                    </div>
                    <div className="div-trie">
                        <label>
                            <input
                                type="radio"
                                value="decroissant"
                                checked={triageActive === "decroissant"}
                                onChange={(e) => setActiveTriage(e.target.value)}
                            ></input>
                            Prix décroissant
                        </label>
                    </div>
                </div>
                <div style={{backgroundColor: "#F3F3F5", padding: "0.5em", margin: "1em 0 1em 0"}}>
                    <h4 style={{margin: 0}}>Filtrer par</h4>
                </div>
                <div style={{display: "flex"}}>
                    <div style={{margin: "1em"}}>
                        {(activeCategory === "Bracelet" || activeCategory === "Boucle Oreille" || activeCategory === "Accessoire") &&
                            <h4>Pierres</h4>}
                        {activeCategory === "Bracelet" || activeCategory === "Boucle Oreille" || activeCategory === "Accessoire" ? (
                            pierresBracelets.map((pierre, index) => (
                                <div key={index} className={"div-une-pierre"}>
                                    <input type="checkbox" id={`pierre-${index}`} value={pierre}
                                           checked={pierresChoisies.includes(pierre)}
                                           onChange={() => handleChoixPierres(pierre)}
                                    />
                                    <label htmlFor={`pierre-${index}`}>{pierre}</label>
                                </div>
                            ))
                        ) : null
                        }
                    </div>
                    <div style={{margin: "1em"}}>
                        {(activeCategory === "Bracelet" || activeCategory === "Boucle Oreille" || activeCategory === "Accessoire") &&
                            <h4>Couleurs</h4>}
                        {activeCategory === "Bracelet" || activeCategory === "Boucle Oreille" || activeCategory === "Accessoire" ? (
                            colors.map((color, index) => (
                                <div key={index} className={"div-une-pierre"}>
                                    <input type="checkbox" id={`color-${index}`} value={color}
                                           checked={couleursChoisies.includes(color)}
                                           onChange={() => handleChoixCouleurs(color)}/>
                                    <label htmlFor={`color-${index}`}>{color}</label>
                                </div>
                            ))
                        ) : null}
                    </div>
                </div>
                <div className="div-max-and-min-filter">
                    <h4>Prix</h4>
                    <p>{range[0]} € - {range[1]} € </p>
                </div>
                <div className="slider-div">
                    <Slider
                        range
                        min={sessionStorage.getItem("filtrePrixBaseCategorie") ? JSON.parse(sessionStorage.getItem("filtrePrixBaseCategorie"))[0] : minPriceForThisCategory}
                        max={sessionStorage.getItem("filtrePrixBaseCategorie") ? JSON.parse(sessionStorage.getItem("filtrePrixBaseCategorie"))[1] : maxPriceForThisCategory}
                        value={range}
                        onChange={(range) =>
                            handleSliderChange(range[0], range[1], range)
                        }
                        trackStyle={[trackStyle]}
                    />
                </div>
                {filtreOuvert ? (
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <button style={{width: "100%", backgroundColor: "#302D5B"}}
                                onClick={() => handleFiltreValider()}>Valider
                        </button>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default FiltreEtTrie;