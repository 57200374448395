import {useLocation, useNavigate, useParams} from "react-router-dom";
import "./DetailsCommande.scss";
import React, {useEffect, useState} from "react";
import NavBarProfil from "../../components/NavBarProfil/NavBarProfil";
import Cookies from "js-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCheck, faCircleInfo, faTruck, faTruckFast, faRotateLeft} from "@fortawesome/free-solid-svg-icons";
import {Loader} from "../../utils/Loader";
import Swal from "sweetalert2";
import VerifyRoleInJWTtoken from "../../services/VerifyRoleInJWTtoken";
import IsAdmin from "../../components/Admin/IsAdmin";
import AccountActiveOrNot from "../../components/AccountActiveOrNot/AccountActiveOrNot";
import ButtonDeconnect from "../../components/Button Deconnect/ButtonDeconnect";
import sendEmailOrderRefund from "../../services/SendEmailOrderRefund";
import TextAccountNotActiveWithDeconnectButton
    from "../../components/AccountActiveOrNot/TextAccountNotActiveWithDeconnectButton";

function DetailsCommande() {
    const location = useLocation();
    const [contenuCommande, setContenuCommande] = useState([]);
    const {idCommande} = useParams();
    const [commande, setCommande] = useState([]);
    const [user, setUser] = useState([]);
    const [adresseId, setAdresseId] = useState("");
    const [adresse, setAdresse] = useState({});
    const [pointRelaisId, setPointRelaisId] = useState("");
    const [pointRelais, setPointRelais] = useState({});
    const jwtToken = Cookies.get("auth_token");
    const [dataLoading, setDataLoading] = useState(false);
    const [dataLoadingCancelOrder, setDataLoadingCancelOrder] = useState(false);
    const [annulerCommande, setAnnulerCommande] = useState(false);
    const [stripePaymentCancel, setStripePaymentCancel] = useState("");
    const date = new Date();
    const dateCorrecte = new Date(commande.date);
    const [prixTotalProduits, setPrixTotalProduits] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();
    dateCorrecte.setHours(dateCorrecte.getHours() + 2);
    const [accountActive, setAccountActive] = useState(null);
    const [raison, setRaison] = useState("");
    let raisonRefus = "";

    function inverserFormatDate(date) {
        const [annee, mois, jour] = date.split('-');
        return `${jour}-${mois}-${annee}`;
    }

    useEffect(() => {
        console.log(isAdmin)
        if (isAdmin === null) {
            return;
        }
    }, [isAdmin]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchCommande = async () => {
            setDataLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}commandes/${idCommande}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );
                const commande = await response.json();
                console.log(commande);
                setCommande(commande);
                setAdresseId(commande.adresseLivraison);
                setPointRelaisId(commande.adressePointRelais);
                setContenuCommande(commande.contenuCommande);
                setDataLoading(false);

                const informationsUser = await fetch(`${process.env.REACT_APP_API_URL}users/get-user-by-id/${commande.idClient}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                const user = await informationsUser.json();
                console.log(user);
                setUser(user);
            } catch (error) {
                console.error(error);
            }
        };
        fetchCommande();
        if (VerifyRoleInJWTtoken()) {
            setIsAdmin(true);
        }
    }, []);

    useEffect(() => {
        if (!adresseId) {
            return;
        }
        const fetchAdresse = async () => {
            console.log(adresseId);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}adresses/${adresseId}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${jwtToken}`
                        },
                    }
                );
                const adresse = await response.json();
                console.log(adresse);
                setAdresse(adresse);
            } catch (error) {
                console.error(error);
            }
        };
        fetchAdresse();
    }, [adresseId]);


    useEffect(() => {
        if (!pointRelaisId) {
            return;
        }
        const fetchPointRelais = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}mondialRelay/objectId/${pointRelaisId}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${jwtToken}`
                        },
                    }
                );
                const pointRelai = await response.json();
                console.log(pointRelai);
                setPointRelais(pointRelai);
            } catch (error) {
                console.error(error);
            }
        };
        fetchPointRelais();
    }, [pointRelaisId]);

    const cancelOrder = async (raison) => {
        Swal.fire({
            text: `${raison === 'annulation' ? 'Etes vous sur de vouloir annuler votre commande ?' : 'Etes vous sur de vouloir rembourser cette commande ?'}`,
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: "Annuler",
            confirmButtonText: `${raison === 'annulation' ? 'Oui supprimer' : 'Oui rembourser'}`
        }).then((result) => {
            if (result.isConfirmed) {
                setDataLoadingCancelOrder(true);
                try {
                    console.log(contenuCommande.idMoyenPaiementStripe)
                    const idModePaiement = commande.idMoyenPaiementStripe;
                    const cancelOrder = async () => {
                        const response = await fetch(
                            `${process.env.REACT_APP_API_URL}payment/cancel-order`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${jwtToken}`,
                                },
                                body: JSON.stringify({token: idModePaiement})
                            }
                        );
                    };
                    cancelOrder();
                    setStripePaymentCancel(raison === "annulation" ? "Annulé" : "Remboursée");
                } catch (error) {
                    console.error(error);
                } finally {
                    setAnnulerCommande(false);
                }
            } else {
                setAnnulerCommande(false);
            }
        })
    }

    useEffect(() => {
        if (!annulerCommande) {
            return;
        }
        updateStatusCommande("Remboursée");
    }, [annulerCommande]);

    useEffect(() => {
        if (!stripePaymentCancel) {
            return;
        }
        try {
            const response = async () => {
                const setCancelOrder = await fetch(
                    `${process.env.REACT_APP_API_URL}commandes/update-status`,
                    {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${jwtToken}`,
                        },
                        body: JSON.stringify({status: stripePaymentCancel, idCommande: idCommande})
                    }
                );

                if (stripePaymentCancel === "Remboursée" || stripePaymentCancel === "Non remboursée") {
                    await sendEmailOrderRefund(user, stripePaymentCancel, raison);
                }

                const commande = await setCancelOrder.json();
                if (commande) {
                    Swal.fire({
                        text: `${stripePaymentCancel === "Remboursée" ? "Commande remboursé avec succès" : stripePaymentCancel === "Non remboursée" ? "Commande non remboursée" : "Votre commande a bien été annulée"}`,
                        icon: "success",
                        showConfirmButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setCommande(commande);
                        }
                    })
                } else {
                    Swal.fire({
                        text: "Une erreur est survenue lors de l'annulation de votre commande",
                        icon: "error",
                        showConfirmButton: true,
                    });
                }
            };
            response();
        } catch (error) {
            console.error(error);
        } finally {
            setDataLoadingCancelOrder(false);
        }
    }, [stripePaymentCancel]);

    const sendOrder = async (idCommande, typeLivraison, idClient) => {
        const domicile = "colissimo";
        const pointRelais = "mondial relay";
        const lienEnvoiePointRelais = "https://www.mondialrelay.fr/envoi-de-colis/";
        const lienEnvoieDomicile = "https://www.laposte.fr/professionnel/envoi-colis/parcours/votre-colis";
        Swal.fire({
            title: "Tu t'apprêtes à valider l'envoi de la commande.",
            html: `J'aurais juste besoin que tu me fournisse le numéro de suivie ${typeLivraison === "A domicile" ? domicile : pointRelais}. Pour ceci, voici le lien ${typeLivraison === "A domicile" ? domicile : pointRelais} pour l'envoi : <a href='${typeLivraison === "A domicile" ? lienEnvoieDomicile : lienEnvoiePointRelais}' target='_blank'>${typeLivraison === "A domicile" ? lienEnvoieDomicile : lienEnvoiePointRelais}</a>.`,
            icon: "warning",
            input: 'text',
            inputPlaceholder: 'Entrez le numéro de suivi du colis',
            showCancelButton: true,
            preConfirm: (trackingNumber) => {
                if (!trackingNumber) {
                    Swal.showValidationMessage('Veuillez entrer le numéro de suivi du colis');
                }
                const commandeData = {
                    idCommande: idCommande,
                    trackingNumber: trackingNumber,
                    livraison: typeLivraison === "A domicile" ? domicile : pointRelais
                };
                try {
                    fetch(`${process.env.REACT_APP_API_URL}commandes/update-tracking-number`, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${jwtToken}`,
                        },
                        body: JSON.stringify(commandeData),
                    });
                    Swal.fire({
                        title: "Numéro de suivie inséré",
                        icon: "success",
                    }).then(response => {
                        if (response.isConfirmed) {
                            sendEmailToClient();
                        }
                    });
                } catch (error) {
                    console.log(error);
                }
                const sendEmailToClient = async () => {
                    setDataLoading(true);
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}users/get-user-by-id/${idClient}`, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });
                        const client = await response.json();
                        const clientEmail = client.adresseEmail;
                        const clientName = client.prenom;
                        const transporteur = typeLivraison === "A domicile" ? "Colissimo" : "Mondial Relay";
                        console.log(clientEmail)
                        const Emailresponse = await fetch(`${process.env.REACT_APP_API_URL}mail/seller-to-client-order-sent`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                numeroSuivi: trackingNumber,
                                email: clientEmail,
                                name: clientName,
                                transporteur: transporteur
                            })
                        });
                        if (Emailresponse.ok) {
                            Swal.fire({
                                title: "Email envoyé au client avec succès",
                                icon: "success",
                            }).then((response) => {
                                if (response.isConfirmed) {
                                    window.location.reload();
                                }
                            });
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setDataLoading(false);
                    }
                }
            },
        })
    }

    const updateStatusCommande = async (status) => {
        console.log(status);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}commandes/update-status`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Cookies.get("auth_token")}`,
                },
                body: JSON.stringify({status: status, idCommande: idCommande})
            });
            const commandeBDD = await response.json();
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    const sendEmailToSeller = async (raison) => {
        setDataLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}users/get-user-by-id/${commande.idClient}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const client = await response.json();
            const clientEmail = client.adresseEmail;
            const clientName = client.prenom;
            const Emailresponse = await fetch(`${process.env.REACT_APP_API_URL}mail/client-to-seller-return-order-sent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Cookies.get("auth_token")}`,
                },
                body: JSON.stringify({
                    name: clientName,
                    email: clientEmail,
                    raison: raison,
                    commande: commande
                })
            });
            if (Emailresponse.ok) {
                Swal.fire({
                    title: "Demande de retour envoyé avec succès, vous serez très bientôt informer par email de la réponse du vendeur.",
                    icon: "success",
                }).then((response) => {
                    if (response.isConfirmed) {
                        updateStatusCommande("Demande de retour");
                    }
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDataLoading(false);
        }
    }

    const handleClickReturnOrder = () => {
        Swal.fire({
            title: "Retourner un produit",
            text: "Pour retourner un colis, le produit doit être dans son emballage d'origine et ne doit pas avoir été utilisé. Merci de choisir une raison de retour.",
            input: 'select',
            inputOptions: {
                option1: 'Ne me convient pas.',
                option2: 'Le produit a un défaut.',
            },
            inputPlaceholder: 'Raison de votre retour',
            showCancelButton: true,
            confirmButtonText: "Confirmer",
            preConfirm(inputValue) {
                if (!inputValue) {
                    Swal.showValidationMessage('Veuillez choisir une raison de retour');
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                let raison = result.value;
                switch (raison) {
                    case "option1":
                        raison = "Ne me convient pas.";
                        break;
                    case "option2":
                        raison = "Le produit a à un défaut";
                        break;
                    default:
                        raison = "Raison inconnue";
                        break;
                }
                console.log(raison);
                sendEmailToSeller(raison);
            }
        });
    }

    useEffect(() => {
        let total = 0;
        contenuCommande.forEach((commande) => {
            total += commande.price * commande.amount;
        });
        setPrixTotalProduits(total);
    }, [contenuCommande]);

    const handleClickRefundOrder = (raison) => {
        cancelOrder("annulation");
    }

    const sendEmailHowToReturnOrder = async (choix) => {
        try {
            console.log(choix, raisonRefus);
            const Emailresponse = await fetch(`${process.env.REACT_APP_API_URL}mail/how-return-order`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Cookies.get("auth_token")}`,
                },
                body: JSON.stringify({
                    email: user.adresseEmail,
                    choix: choix,
                    raison: raisonRefus,
                })
            });
            if (Emailresponse.ok) {
                Swal.fire({
                    title: "Demande de retour accepté / refusée avec succès.",
                    icon: "success",
                }).then((response) => {
                    if (response.isConfirmed) {
                        updateStatusCommande(choix);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDataLoading(false);
        }
    }

    return (
        <>
            <AccountActiveOrNot setAccountActive={setAccountActive}/>
            <IsAdmin setAdmin={setIsAdmin}/>
            {accountActive === false && accountActive !== null ? (
                <TextAccountNotActiveWithDeconnectButton/>
            ) : (
                <div className="div-principal-detailsCommande">
                    <NavBarProfil></NavBarProfil>
                    {dataLoading ? (
                        <div className="loader-div-shopping-list">
                            <Loader/>
                        </div>
                    ) : <div className={"div-details-commande-main"}>
                        <h2 style={{margin: "2em 0 2em 0", textAlign: "center"}}>DÉTAILS DE VOTRE COMMANDE</h2>
                        <div className={"div-secondaire-details-commande"}>
                            <div className={"div-recap-commande-1"}>
                                <h3>Commande N°{commande.idCommande}</h3>
                                <p><b>Date : </b>
                                    {commande.date && inverserFormatDate(commande.date.substring(0, 10))}</p>
                                {commande.status === "Annulé" ? (
                                    <div className={"div-status-commande"}>
                                        <p style={{padding: "0"}}><b>Statut : </b></p>
                                        <div style={{
                                            backgroundColor: "#fee2e2",
                                            color: "#be2d2d",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            padding: "0.3em",
                                            margin: "0 0 0 0.4em",
                                            border: "1px solid #fce7e7",
                                            maxWidth: "6em",
                                            borderRadius: "5px"//#ffffff

                                        }}>
                                            <FontAwesomeIcon icon={faCancel}/>
                                            <p style={{margin: "0 0 0 0.4em"}}>Annulé</p>
                                        </div>
                                    </div>
                                ) : commande.status === "En cours de livraison" ? (
                                    <div className={"div-status-commande"}>
                                        <p style={{padding: "0"}}><b>Statut : </b></p>
                                        <p style={{
                                            backgroundColor: "#bfdbfe",
                                            color: "#1d4ed8",
                                            padding: "0.4em",
                                            margin: "0 0 0 0.4em"
                                        }}>En
                                            cours de
                                            livraison</p>
                                    </div>
                                ) : commande.status === "Livré" ? (
                                    <div className={"div-status-commande"}>
                                        <p style={{padding: "0"}}><b>Statut : </b></p>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            padding: "0.3em",
                                            backgroundColor: "#DCF5D3",
                                            color: "#287834",
                                            margin: "0.4em",
                                            borderRadius: "5px",
                                            maxWidth: "6em",
                                        }}>
                                            <FontAwesomeIcon style={{margin: "0 0.5em 0 0.5em"}} icon={faCheck}/>
                                            <p style={{margin: "0 0.5em 0 0"}}>Livré</p>
                                        </div>
                                    </div>
                                ) : commande.status === "En cours de préparation" ? (
                                    <div className={"div-status-commande"}>
                                        <p style={{padding: "0"}}><b>Statut : </b></p>
                                        <p>En cours de préparation</p>
                                    </div>
                                ) : commande.status === "Demande de retour" ? (
                                    <div className={"div-status-commande"}>
                                        <p style={{padding: "0"}}><b>Statut : </b></p>
                                        <p>Demande de retour</p>
                                    </div>
                                ) : commande.status === "Demande de retour acceptée" ? (
                                    <div className={"div-status-commande"}>
                                        <p style={{padding: "0"}}><b>Statut : </b></p>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            padding: "0.2em",
                                            backgroundColor: "#DCF5D3",
                                            color: "#287834",
                                            margin: "0.4em"
                                        }}>
                                            <FontAwesomeIcon icon={faCheck}/>
                                            <p>Demande de retour acceptée</p>
                                        </div>
                                    </div>
                                ) : commande.status === "Remboursée" ? (
                                    <div className={"div-status-commande"}>
                                        <p style={{padding: "0"}}><b>Statut : </b></p>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            padding: "0.2em",
                                            backgroundColor: "#DCF5D3",
                                            color: "#287834",
                                            margin: "0.4em",
                                            border: "1px solid #cbedd8"
                                        }}>
                                            <FontAwesomeIcon icon={faCheck}/>
                                            <p>Commande rembousée</p>
                                        </div>
                                    </div>
                                ) : commande.status === "Non remboursée" ? (
                                    <div className={"div-status-commande"}>
                                        <p style={{padding: "0"}}><b>Statut : </b></p>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            padding: "0.2em",
                                            backgroundColor: "#fee2e2",
                                            color: "#be2d2d",
                                            margin: "0.4em",
                                        }}>
                                            <FontAwesomeIcon icon={faCancel}/>
                                            <p>Commande non remboursée</p>
                                        </div>
                                    </div>
                                ) : commande.status === "Demande de retour refusée" ? (
                                        <div className={"div-status-commande"}>
                                            <p style={{padding: "0"}}><b>Statut : </b></p>
                                            <div style={{
                                                backgroundColor: "#FCE8E8",
                                                color: "#FF052D",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                padding: "0.3em",
                                                margin: "0 0 0 0.4em",
                                                borderRadius: "7px"
                                            }}>
                                                <FontAwesomeIcon icon={faCancel}/>
                                                <p>Retour refusée</p>
                                            </div>
                                        </div>
                                    ) :
                                    <div className={"div-status-commande"}>
                                        <p style={{padding: "0"}}><b>Statut : </b> Inconnu</p>
                                    </div>
                                }
                                <p><b>Type de livraison:</b> {commande.typeLivraison}</p>
                            </div>
                            <hr id={"vertical-hr"}/>
                            <div className={"div-recap-commande-1"}>
                                {
                                    commande.typeLivraison === "A domicile" ? (
                                        <h3>Votre adresse de livraison</h3>
                                    ) : (
                                        <h3 style={{maxWidth: "10em"}}>Votre point Relais</h3>
                                    )}
                                {commande.typeLivraison === "A domicile" ? (
                                    <div>
                                        <p>{adresse.adresse}</p>
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <p style={{margin: "0 0.5em 0.5em 0"}}>{adresse.codePostal}</p>
                                            <p style={{margin: "0 0.5em 0.5em 0"}}>{adresse.ville}</p>
                                        </div>
                                        <p>{adresse.pays}</p>
                                    </div>
                                ) : (
                                    <div>
                                        <p>{pointRelais.enseigne}</p>
                                        <p>{pointRelais.adresse}</p>
                                        <div style={{display: "flex"}}>
                                            <p style={{margin: "0 0.5em 0 0"}}>{pointRelais.codePostal}</p>
                                            <p>{pointRelais.ville}</p>
                                        </div>
                                        <p>{pointRelais.pays}</p>
                                    </div>
                                )}
                            </div>
                            {commande.typeLivraison === "En point relais" ? (
                                <div style={{width: "100%"}}>
                                    <img src={pointRelais.urlImage} alt={"photo du point relais"}
                                         style={{borderRadius: "0px", height: "100%", width: "100%", margin: "0"}}/>
                                </div>
                            ) : null}
                        </div>
                        <div className={"div-buttons-actions-details-commande"}>
                            {commande.status === "En cours de préparation" && (dateCorrecte > date) && commande.status !== "Demande de retour" ? (
                                dataLoadingCancelOrder ? (
                                    <Loader/>
                                ) : (<button onClick={() => handleClickRefundOrder("annulé")}>
                                        Annuler ma commande
                                    </button>
                                )
                            ) : commande.status !== "Annulé" && commande.status !== "En cours de préparation" && commande.status !== "Demande de retour" && commande.status !== "Demande de retour acceptée" && commande.status !== "Demande de retour refusée" && commande.status !== "Remboursée" && commande.status !== "Non remboursée" ? (
                                <>
                                    <button onClick={() => {
                                        commande.typeLivraison === "En point relais" ? window.open(
                                            "https://www.mondialrelay.fr/suivi-de-colis?numeroExpedition=" + commande.numeroSuivieMondialRelay + "&codePostal=" + commande.codePostalCommande,
                                            "_blank"
                                        ) : window.open("https://www.laposte.fr/outils/suivre-vos-envois?code=" + commande.numeroSuivieColissimo, "_blank")
                                    }}>
                                        <FontAwesomeIcon id={"icon-buttons-actions-details-commande"}
                                                         icon={faTruckFast}></FontAwesomeIcon>
                                        Suivre mon colis
                                    </button>
                                    <button onClick={() => handleClickReturnOrder()}>
                                        <FontAwesomeIcon id={"icon-buttons-actions-details-commande"}
                                                         icon={faRotateLeft}></FontAwesomeIcon>
                                        Retourner un produit
                                    </button>
                                </>
                            ) : commande.status === "Demande de retour" ? (
                                <div style={{
                                    backgroundColor: "#15803d",
                                    borderRadius: "7px",
                                    padding: "0.5em",
                                    color: "#fff"
                                }}>
                                    <p>Demande de retour envoyé avec succès au vendeur. Vous recevrez d'ici peu de temps
                                        une
                                        réponse par email de la part du vendeur.</p>
                                </div>
                            ) : commande.status === "Demande de retour acceptée" ? (
                                <div style={{
                                    backgroundColor: "#15803d",
                                    borderRadius: "7px",
                                    padding: "0.5em",
                                    color: "#fff",
                                    maxWidth: "15%"
                                }}>
                                    <p>Demande de retour acceptée. Vous avez recu un mail, avec les instructions à
                                        suivre
                                        pour renvoyer votre colis.</p>
                                </div>
                            ) : commande.status === "Demande de retour refusée" ? (
                                <div style={{
                                    backgroundColor: "red",
                                    borderRadius: "7px",
                                    padding: "0.5em",
                                    color: "#fff"
                                }}>
                                    <p>Demande de retour refusé par le vendeur, vous pouvez avoir les détails du refus
                                        dans
                                        le mail envoyé.</p>
                                </div>
                            ) : commande.status === "Annulé" ? (
                                <div style={{
                                    backgroundColor: "#15803d",
                                    borderRadius: "7px",
                                    padding: "0.5em",
                                    color: "#fff"
                                }}>
                                    <p>Commande annulé avec succès. Votre commande vous à été remboursez, vous recevrez
                                        l'argent au maximum d'ici 5 jours.</p>
                                </div>
                            ) : commande.status === "Remboursée" ? (
                                <div style={{
                                    textAlign: "center",
                                    backgroundColor: "green",
                                    borderRadius: "7px",
                                    padding: "0.5em",
                                    color: "#fff",
                                    maxWidth: "70%"
                                }}>
                                    <p>Commande remboursé avec succès. Vous recevrez l'argent d'ici 5 jours maximum. Si
                                        d'ici là vous ne recevez toujours pas votre argent. Veuillez nous contacter par
                                        email en nous fournissant votre numéro de commande associé au remboursement.</p>
                                </div>
                            ) : commande.status === "Non remboursée" && (
                                <div style={{
                                    backgroundColor: "red",
                                    borderRadius: "7px",
                                    padding: "0.5em",
                                    color: "#fff"
                                }}>
                                    <p>Commande non remboursé. Vous allez recevoir un email avec la raison de pourquo
                                        cela.</p>
                                </div>
                            )
                            }
                        </div>
                        <div className="div-commandes">
                            <h4>{contenuCommande.nbArticles}</h4>
                            {commande ? (
                                contenuCommande.map((commande) => {
                                    return (
                                        <div className={"div-produit-details-commande"}>
                                            <div className={'div-images-details-commande'}>
                                                <img
                                                    src={commande.cover}
                                                    alt="image produit collections"
                                                    style={{
                                                        borderRadius: "2px",
                                                        objectFit: "cover",
                                                        width: "100%"
                                                    }}
                                                ></img>
                                            </div>
                                            <div className={'div-tertiaire-produit-details-commande'}>
                                                <p style={{margin: "0 0 0 1em"}}>{commande.name}</p>
                                                {commande.informationsComplementaires ?
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        backgroundColor: "#031633",
                                                        borderRadius: "7px",
                                                        padding: "0.4em",
                                                        maxWidth: "5em",
                                                        color: "#fff"
                                                    }}>
                                                        <FontAwesomeIcon icon={faCircleInfo}/>
                                                        <p style={{margin: "0 0 0 0.5em"}}>{commande.informationsComplementaires}</p>
                                                    </div> : null}
                                                <p>Prix : {commande.price} €</p>
                                                <p>x{commande.amount}</p>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div>
                                    <h2>Aucune commande pour le moment</h2>
                                </div>
                            )}
                        </div>
                        <div className={'div-recap-details-commande'}>
                            <div className={"div-prix-details-commande"}>
                                <p><b>Total :</b></p>
                                <p>{(prixTotalProduits).toFixed(2)} €</p>
                            </div>
                            <div className={"div-prix-details-commande"}>
                                <p><b>Frais de livraison :</b></p>
                                <p>{commande.fraisLivraison} €</p>
                            </div>
                            <div className={"div-prix-details-commande"}>
                                <p><b>Total commande TTC :</b></p>
                                <p id={"prix-total-details-commande"}>{commande.prixTotal && (commande.prixTotal).toFixed(2)} €</p>
                            </div>
                        </div>
                        {!isAdmin ? null : (
                            <>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <hr style={{border: "0", borderBottom: "1px solid #000"}}/>
                                </div>
                                <div className={"div-section-admin-details-commande"}>
                                    <h2 style={{textDecoration: "underline", textUnderlineOffset: '0.3em'}}>Partie
                                        administrateur</h2>
                                    <h4>Informations complémentaires sur le client</h4>
                                    <div>
                                        <p><b>Commande de</b> : {user.nom} {user.prenom}</p>
                                        <p><b>Email :</b> {user.adresseEmail}</p>
                                        <p><b>Civilité</b> : {user.civilite}</p>
                                        <p><b>Téléphone :</b> {user.numeroTel}</p>
                                    </div>
                                    <h2>Statut de commande : <u>{commande.status}</u></h2>
                                    {commande.status === "En cours de préparation" && (
                                        <button
                                            style={{
                                                padding: "0.5em 1em",
                                                backgroundColor: "green",
                                                color: "white",
                                                border: "none"
                                            }}
                                            onClick={() => sendOrder(commande.idCommande, commande.typeLivraison, commande.idClient)}
                                        >
                                            Valider envoie
                                        </button>
                                    )}
                                    {commande.status === "En cours de livraison" && (
                                        <button
                                            style={{
                                                padding: "0.5em 1em",
                                                margin: "0 0 0 1em",
                                                backgroundColor: "blue",
                                                color: "white",
                                                border: "none"
                                            }}
                                            onClick={() => window.open(
                                                commande.typeLivraison === "En point relais" ? "https://www.mondialrelay.fr/suivi-de-colis?numeroExpedition=" + commande.numeroSuivieMondialRelay + "&codePostal=" + commande.codePostalCommande
                                                    : "https://www.laposte.fr/outils/suivre-vos-envois?code=" + commande.numeroSuivieColissimo,
                                                "_blank"
                                            )}
                                        >
                                            Suivre le colis
                                        </button>
                                    )}
                                    {commande.status === "Demande de retour" && (
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <button
                                                style={{
                                                    padding: "0.5em 1em",
                                                    margin: "0 0 0 1em",
                                                    backgroundColor: "green",
                                                    color: "white",
                                                    border: "none"
                                                }}
                                                onClick={() => {
                                                    Swal.fire({
                                                        title: "Accepter la demande de retour",
                                                        text: "Etes vous sur de vouloir accepter la demande de retour ? Cela enverra un email au client concerné avec les étapes à suivre pour renvoyer son colis.",
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonText: "Oui, accepter",
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            sendEmailHowToReturnOrder("Demande de retour acceptée");
                                                        }
                                                    });
                                                }}
                                            >
                                                Accepter la demande de retour
                                            </button>
                                            <button
                                                style={{
                                                    padding: "0.5em 1em",
                                                    margin: "0 0 0 1em",
                                                    backgroundColor: "red",
                                                    color: "white",
                                                    border: "none"
                                                }}
                                                onClick={() => {
                                                    Swal.fire({
                                                        title: "Refusé la demande de retour",
                                                        text: "Etes vous sur de vouloir refusé la demande de retour ?",
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonText: "Oui, refusée",
                                                        input: 'text',
                                                        inputPlaceholder: 'Raison du refus',
                                                        preConfirm(raisonConfirm) {
                                                            if (!raisonConfirm) {
                                                                Swal.showValidationMessage('Veuillez choisir une raison de refus de retour de colis');
                                                            }
                                                            raisonRefus = raisonConfirm;
                                                        },
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            sendEmailHowToReturnOrder("Demande de retour refusée", raison);
                                                        }
                                                    });
                                                }}
                                            >
                                                Refusée la demande de retour
                                            </button>
                                        </div>
                                    )}
                                    {commande.status === "Demande de retour acceptée" && (
                                        <button
                                            style={{
                                                padding: "0.5em 1em",
                                                margin: "0 0 0 1em",
                                                backgroundColor: "green",
                                                color: "white",
                                                border: "none"
                                            }}
                                            onClick={() => {
                                                cancelOrder("remboursement");
                                            }}
                                        >
                                            Rembourser le client
                                        </button>
                                    )}
                                    {commande.status === "Demande de retour acceptée" && (
                                        <button
                                            style={{
                                                padding: "0.5em 1em",
                                                margin: "0 0 0 1em",
                                                backgroundColor: "red",
                                                color: "white",
                                                border: "none"
                                            }}
                                            onClick={() => {
                                                Swal.fire({
                                                    title: "Refuser le remboursement",
                                                    text: "Etes vous sur de vouloir refuser le remboursement ?",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonText: "Oui, refuser",
                                                    input: 'text',
                                                    inputPlaceholder: 'Raison du refus',
                                                    preConfirm(raisonConfirm) {
                                                        if (!raisonConfirm) {
                                                            Swal.showValidationMessage('Veuillez choisir une raison de refus de remboursement');
                                                        }
                                                        setRaison(raisonConfirm);
                                                    },
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        setStripePaymentCancel("Non remboursée")
                                                    }
                                                });
                                            }}
                                        >
                                            Refuser le remboursement
                                        </button>
                                    )}
                                </div>
                            </>
                        )}
                    </div>}

                </div>
            )}
        </>
    );
}

export default DetailsCommande;
