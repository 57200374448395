import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import NavBarProfil from "../../components/NavBarProfil/NavBarProfil";
import "./Adresses.scss";
import CardAdressses from "../../components/CardAdresses/CardAdresses";
import Cookies from "js-cookie";
import {Loader} from "../../utils/Loader";
import AccountActiveOrNot from "../../components/AccountActiveOrNot/AccountActiveOrNot";
import ButtonDeconnect from "../../components/Button Deconnect/ButtonDeconnect";
import TextAccountNotActiveWithDeconnectButton
    from "../../components/AccountActiveOrNot/TextAccountNotActiveWithDeconnectButton";

function Adresses() {
    const navigate = useNavigate();
    const [isDataLoading, setDataLoading] = useState(false);
    const [adresses, setAdresses] = useState([]);
    const [accountActive, setAccountActive] = useState(null);
    console.log(adresses);
    console.log(accountActive)

    const tableauObjet = Object.values(adresses);
    const [btnAddAdresseClique, setbtnAddAdresseClique] = useState(false);

    useEffect(() => {
        document.title = "Mes adresses";
        const fetchAllAdresses = async () => {
            setDataLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}adresses`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("auth_token")}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setAdresses(data);
                }
                setDataLoading(false);
            } catch (error) {
                console.log(error);
            } finally {
                setDataLoading(false);
            }
        };
        fetchAllAdresses();
    }, []);

    useEffect(() => {
        if (btnAddAdresseClique) {
            navigate("/profil/adresses/ajoutAdresse");
        }
    }, [btnAddAdresseClique]);

    return (
        <>
            <AccountActiveOrNot setAccountActive={setAccountActive}/>
            {accountActive === false && accountActive !== null ? (
                <TextAccountNotActiveWithDeconnectButton/>
            ) : (
                <div className="div-main-page-adresses">
                    <NavBarProfil></NavBarProfil>
                    <div className={"div-container-right"}>
                        <div>
                            <h1>Mes adresses</h1>
                        </div>
                        {isDataLoading ? <Loader></Loader> : adresses.length > 0 ? (
                            tableauObjet.map((adresse) => (
                                <CardAdressses
                                    adresseId={adresse._id}
                                    nomPersonne={adresse.nomPersonne}
                                    prenomPersonne={adresse.prenomPersonne}
                                    adresse={adresse.adresse}
                                    codePostal={adresse.codePostal}
                                    pays={adresse.pays}
                                    ville={adresse.ville}
                                    complementAdresse={adresse.complementAdresse}
                                    numTel={adresse.numTel}
                                    setAdresses={setAdresses}
                                    adresses={adresses}
                                ></CardAdressses>
                            ))
                        ) : (
                            <div style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                height: "90dvh",
                                flexDirection: "column"
                            }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column"
                                }}>
                                    <h3>Aucune adresse pour le moment</h3>
                                </div>
                            </div>
                        )}
                        <button id={"btn-add-adress"} onClick={() => setbtnAddAdresseClique(true)}
                                style={{margin: "0 0 3em 0", padding: "1em"}}>
                            Ajouter une adresse
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default Adresses;
