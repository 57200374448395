import Swal from "sweetalert2";

const uploadImage = async (formValid, setLienImageProduit, image) => {
    if (!formValid || image.length === 0) {
        console.log("formValid");
        await Swal.fire({
            text: "Veuillez remplir tous les champs",
            icon: "error"
        });
        return;
    }

    try {
        const formData = new FormData();
        image.forEach(img => {
            formData.append('file', img);
        });

        const response = await fetch(`${process.env.REACT_APP_API_URL}images/upload`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Erreur lors du téléchargement de l\'image');
        }

        const results = await response.json();
        setLienImageProduit(results);
    } catch (error) {
        console.error(error);
    }
};

export default uploadImage;