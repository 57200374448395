import "./NavigationProductList.scss"
import {useEffect, useState} from "react";
import {useFiltre} from "../../utils/FiltreContext";
import {faSliders} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

function NavigationProductList({setProductList, setActiveCategory, activeCategory, setFiltreClique}) {
    const [category, setCategory] = useState("");
    const [mainCategory, setMainCategory] = useState(sessionStorage.getItem('mainCategory') ? sessionStorage.getItem('mainCategory') : '');
    const [categoryFiltre, setCategoryFiltre] = useState(sessionStorage.getItem('categorieFiltre') ? sessionStorage.getItem('categorieFiltre') : '');
    const [bijouxMobileClique, setBijouxMobileClique] = useState(false);
    const [zenMobileClique, setZenMobileClique] = useState(false);
    const [bienEtreMobileClique, setBienEtreMobileClique] = useState(false);
    const {filtreOuvert, setFiltreOuvert, toggleFiltre} = useFiltre();

    useEffect(() => {
        if (category === "" || category === null) {
            return;
        }
        try {
            const fetchProductsFromOneCategory = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}products/category/${category}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
                    const products = await response.json();
                    setProductList(products);
                    setActiveCategory(category);
                    setCategoryFiltre(category.replace(/"/g, ''))
                } catch (error) {
                    console.error("Erreur de connexion au serveur:", error);
                }
            };
            fetchProductsFromOneCategory();
        } catch (error) {
            console.log(error)
        }
    }, [category]);

    useEffect(() => {
        sessionStorage.setItem('categorieFiltre', categoryFiltre);
        sessionStorage.removeItem('filtrePrix');
    }, [categoryFiltre]);

    useEffect(() => {
        sessionStorage.setItem('mainCategory', mainCategory);
    }, [mainCategory]);

    return (
        <>
            <div className={'main-div-navigation-products'}>
                <div className={'div-category-first-and-secondary'}>
                    <h3 style={mainCategory === "Bijoux" ? {
                        fontWeight: "bold",
                        color: "#ae445a"
                    } : {}} onClick={() => {
                        setCategory("Bijoux");
                        setMainCategory("Bijoux")
                    }}>Bijoux et accessoires</h3>
                    <div className={"div-category-secondary"}>
                        <p onClick={() => {
                            setCategory("Bracelet");
                            sessionStorage.removeItem('pierresChoisies');
                            setMainCategory("Bijoux")
                        }} style={activeCategory === "Bracelet" ? {
                            fontWeight: "bold",
                            color: "#ae445a"
                        } : {}}>Bracelet</p>
                        <p onClick={() => {
                            setCategory("Boucle Oreille");
                            sessionStorage.removeItem('pierresChoisies');
                            setMainCategory("Bijoux")
                        }} style={activeCategory === "Boucle Oreille" ? {
                            fontWeight: "bold",
                            color: "#ae445a"
                        } : {}}>Boucle d'oreille</p>
                        <p onClick={() => {
                            setCategory("Accessoire");
                            setMainCategory("Bijoux")
                        }} style={activeCategory === "Accessoire" ? {
                            fontWeight: "bold",
                            color: "#ae445a"
                        } : {}}>Accessoire</p>
                    </div>
                </div>
                <div className={'div-category-first-and-secondary'}>
                    <h3 style={mainCategory === "Zen" ? {
                        fontWeight: "bold",
                        color: "#ae445a"
                    } : {}} onClick={() => {
                        setCategory("Zen");
                        setMainCategory("Zen")
                    }}>Ambiance zen</h3>
                    <div className={"div-category-secondary"}>
                        <p onClick={() => {
                            setCategory("Lampe sel");
                            setMainCategory("Zen")
                        }} style={activeCategory === "Lampe sel" ? {
                            fontWeight: "bold",
                            color: "#ae445a"
                        } : {}}>Lampe de sel</p>
                        <p onClick={() => {
                            setCategory("Fontaine interieur");
                            setMainCategory("Zen")
                        }}
                           style={activeCategory === "Fontaine interieur" ? {
                               fontWeight: "bold",
                               color: "#ae445a"
                           } : {}}>Fontaine d'intérieur</p>
                        <p onClick={() => {
                            setCategory("Diffuseur");
                            setMainCategory("Zen")
                        }} style={activeCategory === "Diffuseur" ? {
                            fontWeight: "bold",
                            color: "#ae445a"
                        } : {}}>Diffuseur d'ambiance</p>
                        <p onClick={() => {
                            setCategory("Statue");
                            setMainCategory("Zen")
                        }} style={activeCategory === "Statue" ? {
                            fontWeight: "bold",
                            color: "#ae445a"
                        } : {}}>Statue</p>
                        <p onClick={() => {
                            setCategory("bruleur_parfum");
                            setMainCategory("Zen")
                        }} style={activeCategory === "bruleur_parfum" ? {
                            fontWeight: "bold",
                            color: "#ae445a"
                        } : {}}>Brûleur parfum</p>
                    </div>
                </div>
                <div className={'div-category-first-and-secondary'}>
                    <h3 style={mainCategory === "Bien être" ? {
                        fontWeight: "bold",
                        color: "#ae445a"
                    } : {}} onClick={() => {
                        setCategory("Bien être");
                        setMainCategory("Bien être")
                    }}>Bien être</h3>
                    <div className={"div-category-secondary"}>
                        <p onClick={() => {
                            setCategory("Purifier");
                            setMainCategory("Bien être")
                        }} style={activeCategory === "Purifier" ? {
                            fontWeight: "bold",
                            color: "#ae445a"
                        } : {}}>Purifier / Assaisir</p>
                        <p onClick={() => {
                            setCategory("Plateau recharge");
                            setMainCategory("Bien être")
                        }}
                           style={activeCategory === "Plateau recharge" ? {
                               fontWeight: "bold",
                               color: "#ae445a"
                           } : {}}>PLateau de recharge</p>
                        <p onClick={() => {
                            setCategory("Orgonite");
                            setMainCategory("Bien être")
                        }} style={activeCategory === "Orgonite" ? {
                            fontWeight: "bold",
                            color: "#ae445a"
                        } : {}}>Orgonite</p>
                    </div>
                </div>
                <div className={"div-button-filtrer-trier"} onClick={() => setFiltreOuvert(true)}>
                    <FontAwesomeIcon icon={faSliders}/>
                    <p>Trier & Filter</p>
                </div>
            </div>
            <div className={"main-div-navigation-products-mobile"}>
                {!bijouxMobileClique && !zenMobileClique && !bienEtreMobileClique &&
                    <>
                        <div className={"div-main-category-mobile"}>
                            <h4 style={{margin: "0 1em 0.5em 1em"}} onClick={() => {
                                setBijouxMobileClique(true);
                                setCategory("Bijoux")
                            }}>Bijoux</h4>
                            <h4 onClick={() => {
                                setZenMobileClique(true);
                                setCategory("Zen")
                            }}>Ambiance Zen
                            </h4>
                            <h4 onClick={() => {
                                setBienEtreMobileClique(true);
                                setCategory("Bien etre")
                            }}>Bien être</h4>
                        </div>
                    </>
                }

                {bijouxMobileClique &&
                    <>
                        <div className={"div-header-secondary-category-mobile"}
                             onClick={() => {
                                 setBijouxMobileClique(false);
                                 setBienEtreMobileClique(false);
                                 setZenMobileClique(false);
                                 setCategory("Bijoux")
                             }}>
                            <FontAwesomeIcon icon={faArrowLeft} onClick={() => setBijouxMobileClique(false)}/>
                            <h4 style={{margin: "0 0 0 0.7em"}}>Bijoux</h4>
                        </div>
                        <div className={"div-secondary-category-mobile"}>
                            <p style={{
                                backgroundColor: category === "Bracelet" ? "#040037" : "#ECF3F8",
                                color: category === "Bracelet" ? "#fff" : "#000", margin: "0.5em 0.5em 0 1em"
                            }} onClick={() => setCategory("Bracelet")}>Bracelet</p>
                            <p style={{
                                backgroundColor: category === "Boucle Oreille" ? "#040037" : "#ECF3F8",
                                color: category === "Boucle Oreille" ? "#fff" : "#000"
                            }} onClick={() => setCategory("Boucle Oreille")}>Boucle d'oreille</p>
                            <p style={{
                                backgroundColor: category === "Accessoire" ? "#040037" : "#ECF3F8",
                                color: category === "Accessoire" ? "#fff" : "#000"
                            }} onClick={() => setCategory("Accessoire")}>Accessoire</p>
                        </div>
                    </>
                }
                {zenMobileClique &&
                    <>
                        <div className={"div-header-secondary-category-mobile"} onClick={() => {
                            setBijouxMobileClique(false);
                            setBienEtreMobileClique(false);
                            setZenMobileClique(false);
                            setCategory("Zen")
                        }}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                            <h4 style={{margin: "0 0 0 0.7em"}}>Ambiance zen</h4>
                        </div>
                        <div className={"div-secondary-category-mobile"}>
                            <p style={{
                                backgroundColor: category === "Lampe sel" ? "#040037" : "#ECF3F8",
                                color: category === "Lampe sel" ? "#fff" : "#000"
                            }} onClick={() => setCategory("Lampe sel")}>Lampe de sel</p>
                            <p style={{
                                backgroundColor: category === "Fontaine interieur" ? "#040037" : "#ECF3F8",
                                color: category === "Fontaine interieur" ? "#fff" : "#000"
                            }} onClick={() => setCategory("Fontaine interieur")}>Fontaine d'intérieur</p>
                            <p style={{
                                backgroundColor: category === "Diffuseur" ? "#040037" : "#ECF3F8",
                                color: category === "Diffuseur" ? "#fff" : "#000"
                            }} onClick={() => setCategory("Diffuseur")}>Diffuseur d'ambiance</p>
                            <p style={{
                                backgroundColor: category === "Statue" ? "#040037" : "#ECF3F8",
                                color: category === "Statue" ? "#fff" : "#000"
                            }} onClick={() => setCategory("Statue")}>Statue</p>
                        </div>
                    </>
                }
                {bienEtreMobileClique &&
                    <>
                        <div className={"div-header-secondary-category-mobile"} onClick={() => {
                            setBijouxMobileClique(false);
                            setBienEtreMobileClique(false);
                            setZenMobileClique(false)
                        }}>
                            <FontAwesomeIcon icon={faArrowLeft} onClick={() => setBijouxMobileClique(false)}/>
                            <h4 style={{margin: "0 0 0 0.7em"}}>Bien être</h4>
                        </div>
                        <div className={"div-secondary-category-mobile"}>
                            <p style={{
                                backgroundColor: category === "Purifier" ? "#040037" : "#ECF3F8",
                                color: category === "Purifier" ? "#fff" : "#000"
                            }} onClick={() => setCategory("Purifier")}>Purifier / Assaisir</p>
                            <p style={{
                                backgroundColor: category === "Plateau recharge" ? "#040037" : "#ECF3F8",
                                color: category === "Plateau recharge" ? "#fff" : "#000"
                            }} onClick={() => setCategory("Plateau recharge")}>PLateau de recharge</p>
                            <p style={{
                                backgroundColor: category === "Orgonite" ? "#040037" : "#ECF3F8",
                                color: category === "Orgonite" ? "#fff" : "#000"
                            }} onClick={() => setCategory("Orgonite")}>Orgonite</p>
                        </div>
                    </>
                }
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#040037",
                    color: "#ffffff",
                    margin: "0 0 5em 0",
                    width: "90%",
                    padding: "0.9em",
                    textAlign: "center",
                }}
                     className={"div-button-filtrer-trier"}
                     onClick={() => setFiltreOuvert(true)}>
                    <FontAwesomeIcon icon={faSliders}/>
                    <p>Trier & Filter</p>
                </div>
            </div>
        </>
    )
}

export default NavigationProductList;
